import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Grid, ClickAwayListener } from '@mui/material'
import { TextError, TextBody1 } from '../Styles/TextCustom'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import ParkingPassInputs from './ParkingPassInputs'
import { UPDATE_PARKING_PASS } from '../../graphql/parking/updateParkingPass'
import { Row } from '../../screens/Parking/SoldSubscriptionList'
import FullScreenLoader from '../Shared/FullScreenLoader'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import ParkingPassSelects from './ParkingPassSelects'
import { themeColors } from '../../const/colors'
import DrawerButtons from '../Shared/DrawerButtons'
import DrawerHeader from '../Shared/DrawerHeader'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import { BaseButton } from '../Shared/BaseButton'
import Input from '../Shared/Input'
import { Title } from '../Styles/TextCustom'
import { CAR_PLATE_REGEX } from '../../const/regex'
import { useAppSelector } from '../../redux/store'
import { checkRole } from '../../utils/checkRole'
import { PARKING_ROLES } from '../../const/permissions'

const EditTicketCarPlates = ({
  data,
  refetch,
}: {
  data?: Row
  refetch: () => void
}) => {
  const { t } = useTranslation(['sold_parking_items'])
  const successNotify = (message: string) => toast.dark(message)
  const { roles } = useAppSelector((state) => state.user)

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>()

  //   const updateCompleted = async (data: IUpdateParkingPoolUserDataData) => {
  //     refetch()
  //     listRefetch()
  //     successNotify(`${t('generic:success_message')}`)
  //   }

  //   const [updateParkingPoolUser, { loading }] = useMutation<
  //     IUpdateParkingPoolUserDataData,
  //     IUpdateParkingPoolUserDataVars
  //   >(UPDATE_PARKING_POOL_USER_DATA, {
  //     onCompleted: updateCompleted,
  //     onError: (err: ApolloError) => alert(err.message),
  //   })

  const updateSharedParking = async (data: FormData) => {
    // await updateParkingPoolUser({
    //   variables: {
    //     id,
    //     carPlate: data.carPlate.trim().toUpperCase(),
    //     carPlate2: data.carPlate2.trim().toUpperCase(),
    //   },
    // })
  }

  //   if (loading) {
  //     return <FullScreenLoader />
  //   }

  if (!checkRole(roles, PARKING_ROLES)) {
    return null
  }

  return (
    <>
      <DetailsWrapper>
        <TitleStyled>{t('assigned_car_plates')}</TitleStyled>
        <Input
          label={t('car_plate')}
          name={'carPlate'}
          control={control}
          errors={errors}
          rules={{
            required: `${t('form:field_required')}`,
            pattern: {
              value: CAR_PLATE_REGEX,
              message: t('form:valid_car_plate'),
            },
          }}
          defaultValue={data?.tickets[0].carPlate || ''}
        />
        <Input
          label={t('car_plate')}
          name={'carPlate2'}
          control={control}
          errors={errors}
          rules={{
            pattern: {
              value: CAR_PLATE_REGEX,
              message: t('form:valid_car_plate'),
            },
          }}
          defaultValue={data?.tickets[0].carPlate2 || ''}
        />
        <BaseButton onClick={handleSubmit(updateSharedParking)}>
          {t('btn:save')}
        </BaseButton>
      </DetailsWrapper>
      {/* <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeDetails()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal> */}
    </>
  )
}

export default EditTicketCarPlates

const GridStyled = styled(Grid)`
  background-color: ${themeColors.background};
  padding: 16px;
  max-width: 600px;
  border-radius: 16px;
`
const TitleStyled = styled(Title)`
  margin-bottom: 16px;
  display: block;
`
