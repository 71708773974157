import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import { InputAdornment, Grid } from '@mui/material'
import styled from 'styled-components'
import Input from '../Shared/Input'
import { TextBody2Medium, TextBody1Medium } from '../Styles/TextCustom'
import { ITableRow } from '../../screens/Lockers/LockerProductsList'
import BuildingSelect from '../Shared/UncontrolledBuildingSelect'
import { themeColors } from '../../const/colors'
import RegulationComponent from '../Shared/RegulationComponent'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'

const LockerProductInputs: FC<{
  control: any
  errors: any
  lockerProduct: ITableRow
  watch: any
  setDisabled: (v: boolean) => void
  disabled?: boolean
  setWithShower: (v: boolean) => void
  withShower: boolean
}> = ({
  control,
  errors,
  lockerProduct,
  watch,
  setDisabled,
  disabled,
  withShower,
  setWithShower,
}) => {
  const { t } = useTranslation(['locker_products'])

  const {
    namePl,
    nameEn,
    building,
    periodNamePl,
    periodNameEn,
    durationDays,
    minAdvanceDays,
    maxAdvanceDays,
    rawPrice,
    priceMessage,
    priceMessageEn,
    seller,
    regulation,
  } = lockerProduct

  return (
    <Container>
      <InputStyled
        label={t('product_name')}
        name={'name'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        defaultValue={namePl || ''}
        data-e2e="locker-product-name-input"
      />
      <InputStyled
        label={t('product_name_en')}
        name={'nameEn'}
        control={control}
        errors={errors}
        defaultValue={nameEn || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="locker-product-name-en-input"
      />
      <Text>{t('with_shower')}:</Text>
      <AvailabilitySwitch
        checked={withShower}
        onChange={() => setWithShower(!withShower)}
        checkedText={'generic:yes'}
        uncheckedText={'generic:no'}
        data-e2e="locker-product-shower-switch"
      />
      <SelectWrapper>
        <BuildingSelect
          control={control}
          errors={errors}
          defaultValue={building.name}
        />
      </SelectWrapper>
      <InputStyled
        label={t('period')}
        name={'periodName'}
        control={control}
        errors={errors}
        defaultValue={periodNamePl || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="locker-product-period-input"
      />
      <InputStyled
        label={t('period')}
        name={'periodNameEn'}
        control={control}
        errors={errors}
        defaultValue={periodNameEn || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="locker-product-period-en-input"
      />
      <ShortInput
        label={t('days_number')}
        type={'number'}
        name={'durationDays'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        defaultValue={durationDays || 0}
        data-e2e="locker-product-days-input"
      />
      <Text>{t('min_advance_days')}</Text>
      <ShortInput
        label={t('days')}
        name={'minAdvanceDays'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        type="number"
        defaultValue={minAdvanceDays || ''}
        data-e2e="locker-product-min-advance-input"
      />
      <Text>{t('max_advance_days')}</Text>
      <ShortInput
        label={t('days')}
        name={'maxAdvanceDays'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        type="number"
        defaultValue={maxAdvanceDays || 0}
        data-e2e="locker-product-max-advance-input"
      />
      <InputStyled
        label={t('price_name')}
        name={'priceMessage'}
        control={control}
        errors={errors}
        defaultValue={priceMessage || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="locker-product-price-name-input"
      />
      <InputStyled
        label={t('price_name_en')}
        name={'priceMessageEn'}
        control={control}
        errors={errors}
        defaultValue={priceMessageEn || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="locker-product-price-name-en-input"
      />
      <ShortInput
        label={t('price')}
        name={'price'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">zł</InputAdornment>,
        }}
        defaultValue={!!rawPrice ? (rawPrice * 0.01).toFixed(2) : ''}
        data-e2e="locker-product-price-input"
      />
      <Text>{t('availability')}:</Text>
      <AvailabilitySwitch
        checked={!disabled}
        onChange={() => setDisabled(!disabled)}
        checkedText={'generic:yes'}
        uncheckedText={'generic:no'}
        data-e2e="locker-product-availability-switch"
      />
      <Text>{t('seller')}:</Text>
      <GridStyled>
        <TextNoMargin>{seller}</TextNoMargin>
      </GridStyled>
      <RegulationComponent
        regulationId={regulation}
        service={'locker'}
        control={control}
        errors={errors}
        watch={watch}
      />
    </Container>
  )
}

export default LockerProductInputs

const InputStyled = styled(Input)`
  max-width: 600px;
  margin-bottom: 8px;
`
const ShortInput = styled(Input)`
  max-width: 170px;
  display: inline-block;
`
const Container = styled.div`
  max-width: 600px;
`
const Text = styled(TextBody2Medium)`
  margin: 8px 0;
  max-width: 600px;
`
const SelectWrapper = styled(Grid)`
  padding: 20px 0;
`
const GridStyled = styled(Grid)`
  background-color: ${themeColors.background};
  max-width: 600px;
  padding: 16px;
`
const TextNoMargin = styled(TextBody1Medium)`
  margin: 0;
`
