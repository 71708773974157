import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DrawerHeader from '../Shared/DrawerHeader'
import { ITableRow } from '../../screens/Lockers/GroupReservationList'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import FullScreenLoader from '../Shared/FullScreenLoader'
import Input from '../Shared/Input'
import LogsContainer from '../Shared/LogsContainer'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { themeColors } from '../../const/colors'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import EditReservationInputs from './EditReservationInputs'
import {
  UPDATE_LOCKER_GROUP_RESERVATION,
  IUpdateLockerGroupReservationVars,
} from '../../graphql/lockers/updateLockerGroupReservation'
import LockerReservationStatusSelect from '../LockerReservation/UncontrolledReservationStatusSelect'
import ReservationDetails from './ReservationDetails'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import {
  CANCEL_LOCKER_GROUP_RESERVATION,
  ICancelGroupReservationVars,
  ICancelGroupReservationData,
} from '../../graphql/lockers/cancelLockerGroupReservation'
import { BaseButton } from '../Shared/BaseButton'
import LeaseTermPickers from '../Lockers/LeaseTermPickers'
import ErrorContainer from '../Shared/ErrorContainer'

type CancelFormData = {
  cancelComment: string
}

const EditLockerGroupReservation = ({
  closeDetails,
  reservation,
  refetch,
}: {
  closeDetails: () => void
  reservation?: ITableRow
  refetch: () => void
}) => {
  const { t } = useTranslation(['locker_group_reservation'])
  const successNotify = (message: string) => toast.dark(message)
  const [errorMessage, setErrorMessage] = useState('')
  const [comment, setComment] = useState('')
  const [confirmModal, setConfirmModal] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const {
    control: cancelControl,
    handleSubmit: handleCancel,
    formState: { errors: cancelErrors },
  } = useForm<CancelFormData>()

  const onUpdateCompleted = () => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
    closeDetails()
  }

  const onCancelCompleted = (data: ICancelGroupReservationData) => {
    const { status, error } = data.cancelGroupReservation
    if (status) {
      successNotify(`${t('reservation_cancelled')}`)
      refetch()
      closeDetails()
    } else {
      error && setErrorMessage(error)
    }
  }

  const [updateLockerGroupReservation, { loading }] =
    useMutation<IUpdateLockerGroupReservationVars>(
      UPDATE_LOCKER_GROUP_RESERVATION,
      {
        onCompleted: onUpdateCompleted,
        onError: (error: ApolloError) => setErrorMessage(error.message),
      }
    )

  const [cancelReservation] = useMutation<
    ICancelGroupReservationData,
    ICancelGroupReservationVars
  >(CANCEL_LOCKER_GROUP_RESERVATION, {
    onCompleted: onCancelCompleted,
    fetchPolicy: 'no-cache',
  })

  if (!reservation || loading) {
    return <FullScreenLoader />
  }
  const { id, status, logs, validFrom, validTo } = reservation

  const cancelled = status === ReservationStatus.cancelled

  const onSubmit = (data: any) => {
    updateLockerGroupReservation({
      variables: {
        id,
        status: data.status,
        validFrom: data.validFrom,
        validTo: data.validTo,
        tenant: data.tenant ? { id: data.tenant.value } : undefined,
        tenantName: data.tenantName,
        tenantEmail: data.tenantEmail,
        price: parseInt(data.price) * 100,
        comment: data.comment,
      },
    })
  }

  const onCancel = (data: any) => {
    const { cancelComment } = data
    setComment(cancelComment)
    setConfirmModal(true)
  }
  const handleCancelSubscription = () => {
    cancelReservation({
      variables: { id, comment },
    })
    setConfirmModal(false)
  }

  return (
    <Content>
      <DrawerHeader
        title={cancelled ? t('reservation_details') : t('edit_reservation')}
        handleClose={closeDetails}
      />
      <ReservationDetails reservation={reservation} />
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        {!cancelled && (
          <SelectWrapper>
            <LockerReservationStatusSelect
              control={control}
              errors={errors}
              defaultValue={status}
            />
          </SelectWrapper>
        )}
        <LeaseTermPickers
          control={control}
          errors={errors}
          defaultFrom={new Date(validFrom)}
          defaultTo={new Date(validTo)}
        />
        <EditReservationInputs
          errors={errors}
          control={control}
          reservation={reservation}
          disabled={cancelled}
        />
        <LogsContainer logs={logs} small />
        {!cancelled && (
          <Container>
            <Input
              label={t('cancellation_comment')}
              name={'cancelComment'}
              control={cancelControl}
              errors={cancelErrors}
              rules={{ required: `${t('form:field_required')}` }}
              multiline
              rows={3}
            />

            <ButtonsWrapper container justifyContent={'center'}>
              <Col item xs={6}>
                <ButtonStyled
                  customColor={themeColors.negative}
                  onClick={handleCancel(onCancel)}
                >
                  {t('cancel_reservation')}
                </ButtonStyled>
              </Col>
              <Col item xs={6}>
                <BaseButton
                  onClick={handleSubmit(onSubmit)}
                  data-e2e="save-button"
                >
                  {t('btn:save_changes')}
                </BaseButton>
              </Col>
            </ButtonsWrapper>
          </Container>
        )}

        {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      </FormStyled>
      <BaseModal
        confirmText={'btn:confirm'}
        cancelText={'btn:exit'}
        open={confirmModal}
        handleClose={(e) => setConfirmModal(false)}
        handleAccept={handleCancelSubscription}
      >
        <ModalContent>{t('cancel_reservation_info')}</ModalContent>
      </BaseModal>
    </Content>
  )
}

export default EditLockerGroupReservation
const FormStyled = styled.form`
  padding: 16px 0 0;
`
const ButtonStyled = styled(BaseButton)`
  height: 57px;
`
const Container = styled(Grid)`
  padding: 16px 0;
`
const SelectWrapper = styled(Grid)`
  padding: 16px 0;
`
const Col = styled(Grid)`
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const ButtonsWrapper = styled(Grid)`
  padding-top: 30px;
`
const Content = styled(Grid)`
  max-width: 500px;
`
