import gql from 'graphql-tag'
import { ILog } from './sharedTypes'

export interface IGetPaymentLogsVars {
  id?: string
}

export interface IGetPaymentLogsData {
  getPayment: {
    id: string
    logs: ILog[]
  }
}

export const GET_PAYMENT_LOGS = gql`
  query getPayment($id: ID!) {
    getPayment(id: $id) {
      id
      logs(order: { desc: created_at }) {
        message
        createdAt: created_at
      }
    }
  }
`
