import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '../Styles/TextCustom'
import { ITableRow } from '../../screens/Tenants/TenantsList'
import AddTenantLimit from '../../components/HID/AddTenantLimit'
import EditTenantLimit from '../../components/HID/EditTenantLimit'
import { IHIDConfig } from '../../graphql/tenant/queryTenantWithDomains'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'

const HIDAccessAccount = ({
  tenantToEdit,
  hidConfig,
  refetchTenant,
}: {
  tenantToEdit: ITableRow
  hidConfig?: IHIDConfig
  refetchTenant: () => void
}) => {
  const { t } = useTranslation(['tenant_access_account'])

  return (
    <DetailsWrapper>
      <Title>{t('hid_account')}</Title>
      {!!hidConfig ? (
        <EditTenantLimit
          tenant={tenantToEdit}
          limit={hidConfig.maxNumberOfMobileCards}
          refetch={refetchTenant}
        />
      ) : (
        <AddTenantLimit tenant={tenantToEdit} refetch={refetchTenant} />
      )}
    </DetailsWrapper>
  )
}

export default HIDAccessAccount
