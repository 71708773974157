import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { useQuery, ApolloError } from '@apollo/client'
import {
  QUERY_PARKING_PLACE_RESERVATIONS,
  IQueryParkingPlaceReservationsData,
  IPlace,
} from '../../graphql/parking/queryParkingPlaceReservations'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import PlaceReservationsTable from '../../components/ParkingPlaces/PlaceReservationsTable'
import ReservationFilters from '../../components/ParkingPlaces/ReservationFilters'
import { Row } from '../../components/ParkingPlaces/PlaceReservationsTable'
import { TextBody1Bold } from '../../components/Styles/TextCustom'
import { themeColors } from '../../const/colors'
import EditPlaceReservation from '../../components/ParkingPlaces/EditPlaceReservation'
import { getCurrentReservation } from '../../utils/getCurrentReservation'
import { sortParkingPlaces } from '../../utils/sortParkingPlaces'
import ErrorContainer from '../../components/Shared/ErrorContainer'

const ParkingPlacesList = () => {
  const { t } = useTranslation(['parking_places'])
  const [openedEditForm, setOpenedEditForm] = useState<Row[]>([])
  const [parkingFilter, setParkingFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState<ReservationStatus | ''>('')
  const [sharedFilter, setSharedFilter] = useState('')
  const [error, setError] = useState('')
  const [rangeFrom, setRangeFrom] = useState<Date | null>(null)
  const [rangeTo, setRangeTo] = useState<Date | null>(null)
  const [places, setPlaces] = useState<IPlace[]>([])

  const { data, loading, refetch } =
    useQuery<IQueryParkingPlaceReservationsData>(
      QUERY_PARKING_PLACE_RESERVATIONS,
      {
        fetchPolicy: 'no-cache',
        onError: (error: ApolloError) => setError(error.message),
      }
    )

  useEffect(() => {
    if (data) {
      let items = data.places

      if (statusFilter) {
        statusFilter === ReservationStatus.available
          ? (items = data.places.filter(
              ({ reservations }) => !getCurrentReservation(reservations)
            ))
          : (items = data.places.filter(
              ({ reservations }) =>
                getCurrentReservation(reservations)?.status === statusFilter
            ))
      }
      if (parkingFilter) {
        items = items.filter(({ parking }) => parking.name === parkingFilter)
      }
      if (sharedFilter) {
        sharedFilter === 'yes'
          ? (items = items.filter(({ shared }) => shared))
          : (items = items.filter(({ shared }) => !shared))
      }
      if (rangeFrom && !rangeTo) {
        items = items.filter(({ reservations }) => {
          return (
            !reservations.length ||
            reservations.some(
              ({ validFrom, validTo, status }) =>
                new Date(validFrom) <= rangeFrom &&
                new Date(validTo) > new Date('2110-12-17T00:00:00') &&
                status === statusFilter
            )
          )
        })
      } else if (rangeFrom && rangeTo) {
        items = items.filter(({ reservations }) => {
          return (
            !reservations.length ||
            reservations.some(
              ({ validFrom, validTo, status }) =>
                new Date(validFrom) <= rangeFrom &&
                new Date(validTo) >= rangeTo &&
                status === statusFilter
            )
          )
        })
      }
      const sortedItems = sortParkingPlaces(items)
      setPlaces(sortedItems)
    }
  }, [statusFilter, data, sharedFilter, parkingFilter, rangeFrom, rangeTo])

  if (!data || loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Title variant="h6">{t('parking_place_reservations')}</Title>
      <PlaceReservationsTable
        places={places}
        status={statusFilter}
        rangeFrom={rangeFrom}
        setOpenedEditForm={setOpenedEditForm}
      >
        <ReservationFilters
          parking={parkingFilter}
          setParking={setParkingFilter}
          status={statusFilter}
          setStatus={setStatusFilter}
          shared={sharedFilter}
          setShared={setSharedFilter}
          rangeFrom={rangeFrom}
          rangeTo={rangeTo}
          setRangeFrom={setRangeFrom}
          setRangeTo={setRangeTo}
        />
      </PlaceReservationsTable>
      {!places.length && (
        <TextBody1Bold color={themeColors.primary}>
          {t('generic:no_results')}
        </TextBody1Bold>
      )}
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedEditForm.length} variant={'temporary'}>
        <EditPlaceReservation
          closeDetails={() => setOpenedEditForm([])}
          parking={parkingFilter}
          status={statusFilter}
          rangeFrom={rangeFrom}
          rangeTo={rangeTo}
          places={openedEditForm}
        />
      </BaseDrawer>
    </>
  )
}

export default ParkingPlacesList

const Title = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
