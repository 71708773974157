import React, { FC, useState } from 'react'
import { useAppSelector } from '../../redux/store'
import { List, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { checkRole } from '../../utils/checkRole'
import { GETRESPONSE_ROLES } from '../../const/permissions'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  MainListItem,
  ListItem,
  ListItemTextStyled,
  LinkStyled,
} from '../Styles/NavListStyle'

interface IGetResponseListItemProps {
  isMenuOpen?: boolean
}

const GetResponseListItem: FC<IGetResponseListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])
  let { pathname } = useLocation()

  const [isGetResponseExpanded, setIsGetResponseExpanded] = useState(false)
  const { roles } = useAppSelector((state) => state.user)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : `/admin/${route}` === pathname

  if (!checkRole(roles, GETRESPONSE_ROLES)) {
    return null
  }

  return (
    <>
      <MainListItem
        onClick={() => setIsGetResponseExpanded(!isGetResponseExpanded)}
        data-e2e="navigation-getresponse"
      >
        <ListItemIcon>
          <FAIcon icon={faPaperPlane as IconProp} />
        </ListItemIcon>
        <ListItemTextStyled primary="GetResponse" />
        {isGetResponseExpanded ? <ExpandLess /> : <ExpandMore />}
      </MainListItem>
      <Collapse in={isMenuOpen && isGetResponseExpanded} timeout="auto">
        <List component="div">
          <LinkStyled to={ROUTES.GETRESPONSE_SYNCHRONIZATION}>
            <ListItem
              selected={isSelected(
                ROUTES.GETRESPONSE_SYNCHRONIZATION,
                isGetResponseExpanded
              )}
              data-e2e="getresponse-synchronization"
            >
              <ListItemText primary={t('getresponse_sync')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.GETRESPONSE_EMAIL_LIST}>
            <ListItem
              selected={isSelected(
                ROUTES.GETRESPONSE_EMAIL_LIST,
                isGetResponseExpanded
              )}
              data-e2e="getresponse-email-list"
            >
              <ListItemText primary={t('email_list')} />
            </ListItem>
          </LinkStyled>
        </List>
      </Collapse>
    </>
  )
}

export default GetResponseListItem
