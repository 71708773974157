import React, { useState, useEffect } from 'react'
import { Typography, Tab, Tabs, TabProps } from '@mui/material'
import styled from 'styled-components'
import MenuTable from '../../components/Gastro/MenuTable'
import { useLazyQuery } from '@apollo/client'
import {
  QUERY_RESTAURANT,
  IQueryRestaurantData,
} from '../../graphql/gastro/queryRestaurant'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { BaseButton } from '../../components/Shared/BaseButton'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../redux/store'
import { themeColors } from '../../const/colors'
import { HeaderContainer } from '../../components/Styles/CustomElementsStyled'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { styled as styledMaterial } from '@mui/material/styles'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type RestaurantsType = {
  id: string
  name: string
}[]
interface StyledTabProps extends TabProps {
  redColor: boolean
}

const TabStyled = styledMaterial(Tab, {
  shouldForwardProp: (prop) => prop !== 'redColor',
})<StyledTabProps>(({ redColor }) => ({
  ...(redColor && {
    color: themeColors.negative,
    '&.Mui-selected': {
      color: themeColors.negative,
    },
    '& .MuiTabs-indicatorSpan': {
      backgroundColor: themeColors.negative,
    },
  }),
}))

const MenuList = () => {
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [openedAddForm, setOpenedAddForm] = useState(false)
  const { t } = useTranslation(['btn'])
  const { isAdmin, managesRestaurants } = useAppSelector((state) => state.user)
  const [restaurants, setRestaurants] = useState<RestaurantsType>()

  const setAvailableRestaurants = (restaurants: RestaurantsType) => {
    setRestaurants(restaurants)
    restaurants.length && setValue(restaurants[0].id as string)
    setLoading(false)
  }

  const onCompleted = (data: IQueryRestaurantData) => {
    data?.queryRestaurant && setAvailableRestaurants(data.queryRestaurant)
    setLoading(false)
  }

  const [queryRestaurant] = useLazyQuery<IQueryRestaurantData>(
    QUERY_RESTAURANT,
    {
      fetchPolicy: 'no-cache',
      onCompleted,
    }
  )

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue)
  }

  useEffect(() => {
    isAdmin ? queryRestaurant() : setAvailableRestaurants(managesRestaurants)
  }, [isAdmin, managesRestaurants, queryRestaurant])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <HeaderContainer>
        <TypographyStyled variant="h6">{'Menu'}</TypographyStyled>
        <BaseButton
          onClick={() => setOpenedAddForm(true)}
          data-e2e="gastro-add-dish"
          icon={faPlus as IconProp}
        >
          {t('btn:add_menu_item')}
        </BaseButton>
      </HeaderContainer>

      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
      >
        {restaurants?.map((restaurant: any) => (
          <TabStyled
            data-e2e="gastro-tab"
            label={restaurant.name}
            value={restaurant.id}
            key={restaurant.id}
            redColor={restaurant.visible !== undefined && !restaurant.visible}
          />
        ))}
      </Tabs>
      <MenuTable
        restaurant={value}
        openedAddForm={openedAddForm}
        closeAddForm={() => setOpenedAddForm(false)}
      />
    </>
  )
}

export default MenuList

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
