import React, { FC, useState } from 'react'
import { useAppSelector } from '../../redux/store'
import { List, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { checkRole } from '../../utils/checkRole'
import { PAYU_ROLES } from '../../const/permissions'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useIsRouteSelected } from '../../hooks/useIsRouteSelected'
import {
  MainListItem,
  ListItem,
  CustomListItemText,
  LinkStyled,
} from '../Styles/NavListStyle'

interface IPayUListItemProps {
  isMenuOpen?: boolean
}

const PayUListItem: FC<IPayUListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])
  const { roles } = useAppSelector((state) => state.user)
  const [isPayuExpanded, setIsPayuExpanded] = useState(false)
  const { isSelected } = useIsRouteSelected(
    ROUTES.PAYU_PAYOUT,
    !!isMenuOpen,
    isPayuExpanded
  )

  if (!checkRole(roles, PAYU_ROLES)) {
    return null
  }

  return (
    <>
      <MainListItem onClick={() => setIsPayuExpanded(!isPayuExpanded)}>
        <ListItemIcon>
          <FAIcon icon={faMoneyBill as IconProp} />
        </ListItemIcon>
        <CustomListItemText primary={'PayU'} />
        {isPayuExpanded ? <ExpandLess /> : <ExpandMore />}
      </MainListItem>
      <Collapse in={isMenuOpen && isPayuExpanded} timeout="auto">
        <List component="div">
          <LinkStyled to={ROUTES.PAYU_PAYOUT}>
            <ListItem selected={isSelected}>
              <ListItemText primary={t('payout')} />
            </ListItem>
          </LinkStyled>
        </List>
      </Collapse>
    </>
  )
}

export default PayUListItem
