import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ApolloError, useQuery } from '@apollo/client'
import { IFormRegulation, IFormError } from '../../types/FormTypes'
import {
  IGetMySolvyRegulationsData,
  GET_MYSOLVY_REGULATIONS_PL,
} from '../../graphql/mySolvy/getMySolvyRegulations'
import { PHONE_REGEX } from '../../const/regex'
import Input from '../Shared/Input'
import renderRegulationContent from '../../utils/regulationsUtils'
import Checkbox from '../Shared/Checkbox'
import { CircularProgress } from '@mui/material'
import { themeColors } from '../../const/colors'

const MySolvyRequirementsForm: React.FC<{
  control: any
  errors: IFormError | any
}> = ({ control, errors }) => {
  const { t } = useTranslation(['form', 'generic'])
  const [errorMessage, setErrorMessage] = useState('')
  const [regulations, setRegulations] = useState<IFormRegulation[]>([])

  const onRegulationsCompleted = (data: IGetMySolvyRegulationsData) => {
    setRegulations([...data.regulations, ...data.regulations2] || [])
  }

  const { loading } = useQuery<IGetMySolvyRegulationsData>(
    GET_MYSOLVY_REGULATIONS_PL,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setErrorMessage(error.message),
      onCompleted: onRegulationsCompleted,
    }
  )

  if (loading) {
    return <CustomProgress />
  }

  return (
    <Container>
      <Input
        label={t('phone')}
        name="mobilePhone"
        control={control}
        errors={errors}
        rules={{
          required: String(t('field_required')),
          pattern: {
            value: PHONE_REGEX,
            message: t('valid_phone'),
          },
        }}
      />
      {regulations.map(({ id, content }) => {
        return (
          <Checkbox
            key={id}
            name={id}
            control={control}
            errors={errors}
            rules={{ required: true }}
          >
            <Text> {renderRegulationContent(content)}</Text>
          </Checkbox>
        )
      })}
    </Container>
  )
}

const Text = styled.span`
  text-wrap: wrap;
  margin: 0;
`
const Container = styled.div`
  width: 400px;
`
const CustomProgress = styled(CircularProgress)`
  color: ${themeColors.primary};
  margin: 16px;
`
export default MySolvyRequirementsForm
