import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Grid } from '@mui/material'
import styled from 'styled-components'
import { useQuery, ApolloError } from '@apollo/client'
import {
  ParkingPlaceStatus,
  IPlace,
} from '../../graphql/parking/queryParkingPlace'
import {
  QUERY_TENANT_PARKING_PLACE,
  IQueryTenantParkingPlaceData,
} from '../../graphql/parking/queryTenantParkingPlace'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import PlacesTable from '../../components/ParkingPlaces/PlacesTable'
import PlaceFilters from '../../components/ParkingPlaces/PlaceFilters'
import { Row } from '../../components/ParkingPlaces/PlacesTable'
import { TextBody1Bold } from '../../components/Styles/TextCustom'
import { themeColors } from '../../const/colors'
import EditPlaces from '../../components/ParkingPlaces/EditPlaces'
import { useLocation } from 'react-router-dom'
import { sortParkingPlaces } from '../../utils/sortParkingPlaces'
import UnassignPlaceFromParkingPool from '../../components/ParkingPlaces/UnassignParkingPoolPlaces'
import ErrorContainer from '../../components/Shared/ErrorContainer'

interface stateType {
  prop: { parkingProp: string | undefined }
}

const TenantParkingPlacesList = () => {
  const { t } = useTranslation(['parking_places'])
  const location = useLocation()
  const state: stateType = location.state as stateType
  const [openedEditForm, setOpenedEditForm] = useState<Row[]>([])
  const [parkingFilter, setParkingFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState<ParkingPlaceStatus | ''>('')
  const [sharedFilter, setSharedFilter] = useState('')
  const [tenantFilter, setTenantFilter] = useState('')
  const [error, setError] = useState('')
  const [places, setPlaces] = useState<IPlace[]>([])
  const [selectedPlaces, setSelectedPlaces] = useState<Row[]>([])

  const { data, loading, refetch } = useQuery<IQueryTenantParkingPlaceData>(
    QUERY_TENANT_PARKING_PLACE,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  useEffect(() => {
    if (state?.prop.parkingProp) {
      setParkingFilter(state.prop.parkingProp)
    }
  }, [state])

  useEffect(() => {
    if (data) {
      let items = data.places
      if (tenantFilter) {
        items = data.places.filter(({ tenant }) => tenant.name === tenantFilter)
      }
      if (statusFilter) {
        items = data.places.filter(({ status }) => status === statusFilter)
      }
      if (parkingFilter) {
        items = items.filter(({ parking }) => parking.name === parkingFilter)
      }
      if (sharedFilter) {
        sharedFilter === 'yes'
          ? (items = items.filter(({ shared }) => shared))
          : (items = items.filter(({ shared }) => !shared))
      }
      const sortedItems = sortParkingPlaces(items)
      setPlaces(sortedItems)
    }
  }, [statusFilter, data, sharedFilter, parkingFilter, tenantFilter])

  if (!data || loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Title variant="h6">{t('tenant_parking_places')}</Title>
      <PlacesTable places={places} setSelectedPlaces={setSelectedPlaces}>
        <>
          <PlaceFilters
            parking={parkingFilter}
            setParking={setParkingFilter}
            status={statusFilter}
            setStatus={setStatusFilter}
            shared={sharedFilter}
            setShared={setSharedFilter}
            tenant={tenantFilter}
            setTenant={setTenantFilter}
            tenantFilter
          />
          <RowContainer>
            {!!selectedPlaces.length && (
              <Text color={themeColors.primary}>{`${selectedPlaces.length} ${t(
                'generic:selected'
              )}`}</Text>
            )}
            <UnassignPlaceFromParkingPool
              tenant={tenantFilter}
              places={selectedPlaces}
              refetch={refetch}
            />
          </RowContainer>
        </>
      </PlacesTable>
      {!places.length && (
        <TextBody1Bold color={themeColors.primary}>
          {t('generic:no_results')}
        </TextBody1Bold>
      )}
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedEditForm.length} variant={'temporary'}>
        <EditPlaces
          closeDetails={() => setOpenedEditForm([])}
          parking={parkingFilter}
          status={statusFilter}
          places={openedEditForm}
          refetch={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default TenantParkingPlacesList

const Title = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
const RowContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Text = styled(TextBody1Bold)`
  margin-right: 16px;
`
