export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  EMAIL_VERIFICATION: '/email-verification',
  RECOVER_PASSWORD: '/recover-password',
  REMIND_PASSWORD: '/remind-password',
  SIGN_UP: '/sign-up',
  SIGN_IN_MY_SOLVY: '/my-solvy-sign-in',
  ADMIN: 'admin/*',
  GARDEN_TICKETS: 'garden/tickets',
  GASTRO_MENU: 'gastro/menu',
  PARKING_PAYMENT_HISTORY: 'parking/payment-history',
  PARKING_SUBSCRIPTIONS_PRODUCTS_LIST: 'parking/subscriptions-products-list',
  PARKING_LIST: 'parking/parking-list',
  PARKING_VALID_SUBSCRIPTIONS: 'parking/sold-subscriptions',
  PARKING_VALID_SUBSCRIPTIONS_WITH_DETAILS:
    'parking/sold-subscriptions/:subscriptionId?',
  PARKING_SOLD_ONE_TIME_TICKETS: 'parking/sold-one-time-tickets',
  PARKING_PLACES: 'parking/places',
  PARKING_TENANT_PLACES: 'parking/tenant-places',
  PARKING_RESERVATIONS: 'parking/reservations',
  PARKING_OCCUPANCY_REPORT: 'parking/occupancy-report',
  PARKING_POOL_OCCUPANCY_REPORT: 'parking/parking-pool-occupancy-report',
  PARKING_MANAGEMENT_REPORT: 'parking/parking-management-report',
  CAR_PLATE_VERIFICATION_REPORT: 'parking/car-plate-verification-report',
  USERS_TENANT_ISSUES: 'users/tenant-issues',
  USERS_LIST: 'users/list',
  USER_LIST: 'user/list',
  USER_LIST_WITH_DETAILS: 'user/list/:userId?',
  USER_ROLES_LIST: 'users/roles',
  UNCONFIRMED_EMAILS_LIST: 'users/unconfirmed-emails',
  USERS_COMPANIES: 'users/companies',
  USER_ACCEPTANCES_TABLE: 'user/acceptances/:usr?',
  DELETE_ACCOUNT_REQUESTS_LIST: 'users/delete-account-requests-list',
  MESSAGES: 'messages',
  INTEGRATIONS: 'integrations',
  SURVEY_HOME: 'survey',
  SURVEY_DETAILS: 'survey/:surveyId',
  OLIVKA: 'olivka',
  LOCKERS: 'lockers',
  LOCKERS_WITH_STATUS: 'lockers/:status?',
  LOCKER_PRODUCTS: 'lockers/products',
  LOCKER_GROUP_RESERVATIONS: 'lockers/group-reservations',
  LOCKER_RESERVATIONS: 'lockers/reservations',
  LOCKER_PAYMENT_HISTORY: 'lockers/payment-history',
  LOCKER_STATISTICS: 'lockers/statistics',
  GETRESPONSE_SYNCHRONIZATION: 'getresponse/synchronization',
  GETRESPONSE_EMAIL_LIST: 'getresponse/email-list',
  EVENT_TICKETS: 'events/tickets',
  EVENTS_SALES_SYSTEM: 'events/sales-system',
  EVENTS_REPORTS: 'events/reports',
  SEND_PUSH: 'push',
  PUSH_LIST: 'push-list',
  TENANT_PARKING: 'parking/tenant-parking',
  PAYU_PAYOUT: 'payu/payout',
  MOBILE_ACCESS_CARD_ISSUES: 'access-card-issues/:issue?',
  MOBILE_ACCESS_CARD_LIST: 'access-card-list',
  MOBILE_ACCESS_ACCOUNT_LIST: 'access-account-list',
  COMMUNICATION_BANNER: 'communication-banner',
  PROMOS: 'promos',
}
