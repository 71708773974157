import { useTranslation } from 'react-i18next'
import { ITableRow } from '../../screens/Lockers/GroupReservationList'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import LockerNumbersContainer from '../LockerReservation/LockerNumbersContainer'

const ReservationDetails = ({ reservation }: { reservation: ITableRow }) => {
  const { t } = useTranslation(['locker_group_reservation'])
  const { building, status, period, createdAt, lockers } = reservation

  const cancelled = status === ReservationStatus.cancelled

  const details = [
    { label: t('building'), value: building },
    { label: t('period'), value: period },
    { label: t('created_at'), value: createdAt },
  ]

  return (
    <Container>
      {details.map(
        ({ label, value }) =>
          value &&
          value !== '-' && (
            <Row key={label}>
              <Text>{`${label}: `}</Text>
              <TextBold>{value}</TextBold>
            </Row>
          )
      )}
      {cancelled && (
        <Row>
          <Text>{'Status: '}</Text>
          <TextBold color={themeColors.error}>{t('cancelled')}</TextBold>
        </Row>
      )}
      <LockerNumbersContainer building={building} lockers={lockers} />
    </Container>
  )
}

export default ReservationDetails

const Row = styled(Grid)`
  padding: 2px;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const Container = styled(Grid)`
  max-width: 500px;
`
