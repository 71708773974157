import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { useQuery, ApolloError } from '@apollo/client'
import {
  QUERY_ONE_TIME_PARKING_TICKET,
  IQueryOneTimeParkingTicketData,
  OneTimeParkingTicketStatus,
} from '../../graphql/parking/queryOneTimeParkingTicket'
import { ILog } from '../../graphql/shared/sharedTypes'
import { useLocation } from 'react-router-dom'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import { getOneTimeTicketStatusColor } from '../../utils/getStatusColor'
import { formatToExcelPrice } from '../../utils/formatPrice'
import OneTimeTicketDetails from '../../components/OneTimeParkingTicket/OneTimeTicketDetails'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'
export interface Row {
  id: string
  validFrom: string
  validTo: string
  validFromCSV: string
  validToCSV: string
  ticketNumber: string
  carPlate: string
  login: string
  email: string
  status: OneTimeParkingTicketStatus
  createdAt: string
  createdAtCSV: string
  parking: string
  price: string
  priceCSV: string
  logs: ILog[]
  tenant: string
  paymentId: string
}

const SoldOneTimeTicketList = () => {
  const { t, i18n } = useTranslation(['sold_parking_items'])
  const [openedDetails, setOpenedDetails] = useState<Row>()
  const [error, setError] = useState('')
  const location = useLocation()
  const columnFilter: any = location?.state
  const lang = i18n.language

  const invisibleColumns = {
    id: false,
    validFromCSV: false,
    validToCSV: false,
    tenant: false,
    createdAtCSV: false,
    priceCSV: false,
  }

  const csvHeaderToDownload = [
    {
      label: t('valid_from'),
      key: 'validFromCSV',
    },
    {
      label: t('valid_to'),
      key: 'validToCSV',
    },
    {
      label: t('ticket_number'),
      key: 'ticketNumber',
    },
    {
      label: t('car_plate'),
      key: 'carPlate',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Tenant',
      key: 'tenant',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('purchase_date'),
      key: 'createdAtCSV',
    },
    {
      label: 'Parking',
      key: 'parking',
    },
    {
      label: `${t('payment_amount')} (PLN)`,
      key: 'priceCSV',
    },
  ]

  const { data, loading, refetch } = useQuery<IQueryOneTimeParkingTicketData>(
    QUERY_ONE_TIME_PARKING_TICKET,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'validFrom',
        header: t('valid_from'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validTo',
        header: t('valid_to'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validFromCSV',
        header: t('valid_from'),
      },
      {
        accessorKey: 'validToCSV',
        header: t('valid_to'),
      },
      {
        accessorKey: 'ticketNumber',
        header: t('ticket_number'),
      },
      {
        accessorKey: 'carPlate',
        header: t('car_plate'),
      },
      {
        accessorKey: 'parking',
        header: 'Parking',
      },
      {
        accessorKey: 'login',
        header: 'Login',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'tenant',
        header: t('tenant'),
      },
      {
        accessorKey: 'status',
        header: t('ticket_status'),
        cell: (value) => t(`parking_ticket_status:${value.getValue()}`),
      },
      {
        accessorKey: 'createdAt',
        header: t('purchase_date'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'createdAtCSV',
        header: t('purchase_date'),
      },
      {
        accessorKey: 'price',
        header: t('payment_amount'),
        cell: (value) => `${value.getValue()} PLN`,
      },
      {
        accessorKey: 'priceCSV',
        header: t('payment_amount'),
      },
    ],
    [t, lang]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      data?.tickets.map(
        ({
          id,
          status,
          ticketNumber,
          validFrom,
          validTo,
          createdAt,
          carPlate,
          user: { login, email, tenant },
          parkingName,
          price,
          logs,
          payment: { paymentId },
        }) => ({
          id,
          status,
          ticketNumber,
          validFrom,
          validTo,
          validFromCSV: dateIso2ExcelFormat(validFrom),
          validToCSV: dateIso2ExcelFormat(validTo),
          carPlate: carPlate,
          login,
          email: email?.email,
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          parking: parkingName,
          price: (price * 0.01).toFixed(2),
          priceCSV: formatToExcelPrice(price),
          logs,
          tenant: tenant?.name,
          paymentId,
        })
      ) || []
    )
  }, [data])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Title variant="h6">{t('sold_one_time_tickets')}</Title>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaderToDownload}
        onRowClick={(rowData: Row) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getOneTimeTicketStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <OneTimeTicketDetails
          closeDetails={() => setOpenedDetails(undefined)}
          data={openedDetails}
          refetch={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default SoldOneTimeTicketList

const Title = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
