import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import Input from '../Shared/Input'
import { TextBody2Medium, TextBody2 } from '../Styles/TextCustom'
import TenantSelect from '../Shared/TenantSelect'
import LeaseTermPickers from '../Lockers/LeaseTermPickers'

const RentLockerInputs: FC<{
  control: any
  errors: IFormError | any
}> = ({ control, errors }) => {
  const { t } = useTranslation(['lockers'])

  return (
    <InputsWrapper>
      <TextWithPadding>{t('lease_term')}:</TextWithPadding>
      <LeaseTermPickers control={control} errors={errors} />
      <TextWithPadding>{t('tenant')}:</TextWithPadding>
      <TextBody2>{t('tenant_tip')}:</TextBody2>
      <SelectWrapper>
        <TenantSelect control={control} errors={errors} />
      </SelectWrapper>

      <InputStyled
        data-e2e="tenant-name-input"
        label={t('company')}
        name={'tenantName'}
        control={control}
        errors={errors}
      />
      <InputStyled
        data-e2e="email-input"
        label={'Email'}
        name={'tenantEmail'}
        rules={{ required: `${t('form:field_required')}` }}
        control={control}
        errors={errors}
      />
      <Text>{t('notes')}:</Text>
      <InputStyled
        data-e2e="comment-input"
        label={t('notes')}
        name={'comment'}
        control={control}
        errors={errors}
        multiline
        rows={4}
      />
    </InputsWrapper>
  )
}

export default RentLockerInputs

const InputStyled = styled(Input)`
  max-width: 500px;
`
const InputsWrapper = styled(Grid)`
  padding: 16px 0;
`
const Text = styled(TextBody2Medium)`
  padding: 0 0 16px;
`
const TextWithPadding = styled(TextBody2Medium)`
  padding: 16px 0;
`
const SelectWrapper = styled(Grid)`
  padding: 8px 0 16px;
`
