import { useMemo, useState, FC, ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2localeString,
  dateIso2ExcelFormat,
} from '../../utils/formatDate'
import BaseDrawer from '../Shared/BaseDrawer'
import { getReservationStatusColor } from '../../utils/getStatusColor'
import ReservationTableActions from './ReservationTableActions'
import RentLockerManually from '../LockerRent/RentLockerManually'
import { LockerType, ILocker } from '../../graphql/lockers/queryLockers'
import ReservationSummary from '../LockerProtocols/ReservationSummary'
import { getCurrentActiveReservation } from '../../utils/getCurrentReservation'
import {
  ReservationStatus,
  IReservation,
} from '../../graphql/shared/sharedTypes'
import { IBuilding } from '../../graphql/lockers/queryLockerGroupReservation'
import styled from 'styled-components'
import TooltipButton from '../Shared/TooltipButton'
import { Grid } from '@mui/material'
import EditLockerReservation from './EditLockerReservation'
import { useAppSelector } from '../../redux/store'
import { checkRole } from '../../utils/checkRole'
import { LOCKER_ADMIN } from '../../const/permissions'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  id: string
  buildingName: string
  building: IBuilding
  name: string
  lockerName: string
  key: string
  transponder: string
  reservationStatus: ReservationStatus
  leaseTerm?: string
  tenant?: string
  tenantId?: string
  user: string
  login?: string
  notes?: string
  type: LockerType
  validFrom?: string
  validTo?: string
  email?: string
  createdAt?: string
  logs?: {
    createdAt: string
    message: string
  }[]
  reservation?: IReservation
  reservations: IReservation[]
}

const LockerReservationTable: FC<{
  lockers: ILocker[]
  children: ReactNode
  refetch: () => void
  building: string
  selectedRows: Row[]
  setSelectedRows: (v: Row[]) => void
}> = ({
  lockers,
  children,
  refetch,
  building,
  selectedRows,
  setSelectedRows,
}) => {
  const { t, i18n } = useTranslation(['lockers'])
  const lang = i18n.language
  const [rentLockerManually, setRentLockerManually] = useState<Row>()
  const [openedProtocol, setOpenedProtocol] = useState<Row[]>([])
  const [pickUpProtocol, setPickUpProtocol] = useState(false)
  const [editLockerReservation, setEditLockerReservation] = useState<Row>()
  const { roles } = useAppSelector((state) => state.user)

  const handleSelectRows = useCallback((selectedRowsData: Row[]) => {
    setSelectedRows(selectedRowsData)
  }, [])

  const handleLockerReturnProtocol = () => {
    setPickUpProtocol(true)
    setOpenedProtocol(selectedRows)
  }

  const handleCloseProtocol = () => {
    setPickUpProtocol(false)
    setOpenedProtocol([])
  }

  const invisibleColumns = {
    id: false,
    type: false,
    validFrom: false,
    validTo: false,
    createdAt: false,
    notes: false,
    actions: checkRole(roles, LOCKER_ADMIN),
  }

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: t('building'),
      key: 'buildingName',
    },
    {
      label: t('locker_number'),
      key: 'name',
    },
    {
      label: 'Status',
      key: 'reservationStatus',
    },
    {
      label: t('from'),
      key: 'validFrom',
    },
    {
      label: t('to'),
      key: 'validTo',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
    {
      label: t('user'),
      key: 'user',
    },
    {
      label: t('created_at'),
      key: 'createdAt',
    },
  ]

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'buildingName',
        header: t('building'),
      },
      {
        accessorKey: 'name',
        header: t('locker_number'),
      },
      {
        accessorKey: 'type',
        header: t('locker_type'),
      },
      {
        accessorKey: 'lockerName',
        header: t('locker_type'),
      },
      {
        accessorKey: 'reservationStatus',
        header: t('reservation_status'),
        cell: (value) => t(value.getValue()),
        meta: {
          filterVariant: 'select',
          options: [
            {
              value: ReservationStatus.available,
              label: t(`lockers:${ReservationStatus.available}`),
            },
            {
              value: ReservationStatus.reserved,
              label: t(`lockers:${ReservationStatus.reserved}`),
            },
            {
              value: ReservationStatus.rented,
              label: t(`lockers:${ReservationStatus.rented}`),
            },
          ],
        },
      },
      {
        accessorKey: 'leaseTerm',
        header: t('lease_term'),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validFrom',
        header: t('from'),
        cell: (value) => value && dateIso2ExcelFormat(value.getValue()),
      },
      {
        accessorKey: 'validTo',
        header: t('to'),
        cell: (value) => value && dateIso2ExcelFormat(value.getValue()),
      },
      {
        accessorKey: 'tenant',
        header: t('tenant'),
      },
      {
        accessorKey: 'user',
        header: t('user'),
      },
      {
        accessorKey: 'createdAt',
        header: t('created_at'),
        cell: (value) => value && dateIso2ExcelFormat(value.getValue()),
      },
      {
        accessorKey: 'notes',
        header: t('notes'),
      },
      {
        accessorKey: 'actions',
        header: '',
        cell: (value) => (
          <ReservationTableActions
            data={value.row.original}
            setRentLockerManually={setRentLockerManually}
            setOpenedProtocol={setOpenedProtocol}
            setPickUpProtocol={setPickUpProtocol}
          />
        ),
        enableColumnFilter: false,
      },
    ],
    [t]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      lockers.map(
        ({
          id,
          name,
          type,
          building,
          transponder,
          key,
          notes,
          reservations,
          logs,
          currentTenant,
          currentUser,
          currentReservation,
        }) => {
          const displayDate = (date: string) =>
            dateIso2localeString(date, lang, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          const reservation =
            currentReservation || getCurrentActiveReservation(reservations)
          return {
            id,
            buildingName: building.name,
            building,
            name,
            key,
            transponder,
            reservationStatus: reservation
              ? reservation.status
              : ReservationStatus.available,
            leaseTerm: reservation
              ? `${displayDate(reservation.validFrom)} - ${displayDate(
                  reservation.validTo
                )}`
              : '-',
            tenant: reservation?.tenant ? reservation.tenant?.name : '',
            tenantId: reservation?.tenant && reservation.tenant?.id,
            notes,
            lockerName: !!currentReservation?.order
              ? currentReservation.order?.product?.name
              : t(type),
            type,
            logs,
            email: reservation?.user
              ? reservation?.user?.email?.email
              : reservation?.tenant?.emails[0]?.email,
            validFrom: reservation?.validFrom,
            validTo: reservation?.validTo,
            user: reservation?.user
              ? `${reservation.user?.firstName} ${reservation.user?.lastName}`
              : '',
            login: reservation?.user?.login,
            createdAt: reservation?.createdAt,
            reservation,
            reservations,
          }
        }
      ) || []
    )
  }, [lockers, lang, t])

  return (
    <>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        onSelectionChange={handleSelectRows}
        // isFiltersOff={true}
        onRowClick={(rowData: Row) => {
          checkRole(roles, LOCKER_ADMIN) &&
            rowData &&
            rowData.reservationStatus !== ReservationStatus.available &&
            setEditLockerReservation(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'reservationStatus'
            ? {
                style: {
                  color: getReservationStatusColor(
                    row.original.reservationStatus
                  ),
                  fontWeight: 600,
                },
              }
            : {}
        }
      >
        {children}
        {checkRole(roles, LOCKER_ADMIN) && (
          <RowContainer>
            <TooltipButton
              onClick={handleLockerReturnProtocol}
              disabled={!building || !selectedRows.length}
              hint={t('protocol_button_hint')}
              data-e2e="selected-lockers-protocol-button"
            >
              {t('return_protocol')}
            </TooltipButton>
          </RowContainer>
        )}
      </Table>
      <BaseDrawer
        open={
          !!rentLockerManually ||
          !!openedProtocol.length ||
          !!editLockerReservation
        }
        variant={'temporary'}
      >
        {!!rentLockerManually && (
          <RentLockerManually
            closeDetails={() => setRentLockerManually(undefined)}
            locker={rentLockerManually}
            refetch={refetch}
          />
        )}
        {!!openedProtocol.length && (
          <ReservationSummary
            closeDrawer={handleCloseProtocol}
            reservations={openedProtocol}
            pickUp={pickUpProtocol}
          />
        )}
        {!!editLockerReservation && (
          <EditLockerReservation
            closeDrawer={() => setEditLockerReservation(undefined)}
            lockerReservation={editLockerReservation}
            refetch={refetch}
          />
        )}
      </BaseDrawer>
    </>
  )
}

export default LockerReservationTable

const RowContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
`
