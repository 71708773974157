import { Grid } from '@mui/material'
import styled from 'styled-components'
import {
  TextBody2Medium,
  TextBody2Bold,
  TextBody1Bold,
} from '../Styles/TextCustom'
import { useAppSelector } from '../../redux/store'
import { handleProtocolTitle } from '../../utils/handleProtocolTitle'
import { IProtocolProps } from './Protocol'

const ProtocolContent = (props: IProtocolProps) => {
  const { tenantData, building, reservations, pickUp, user, seller } = props
  const { name, street, city, zipcode, annotation, representative } = tenantData
  const { firstName, lastName } = useAppSelector((state) => state.user)

  return (
    <>
      {pickUp ? (
        <>
          <CentredText>
            {`Protokół odebrania ${handleProtocolTitle(reservations)} „${
              building.name
            }” zlokalizowanego przy ${building.location}${
              annotation ? `, ${annotation}` : ''
            }`}
          </CentredText>
          <PaddingContainer>
            <Row>
              {user ? (
                <TextBold>{`Przekazujący: ${user} `}</TextBold>
              ) : (
                <>
                  <TextBold>{`Przekazujący: ${name} `}</TextBold>
                  <Text>
                    {`z siedzibą w ${city} (${zipcode}), przy ${street}, reprezentowana przez: ${representative}.  `}
                  </Text>
                </>
              )}
            </Row>

            <Row>
              <TextBold>{`Odbierający: ${
                seller?.name || building.seller?.name
              } `}</TextBold>
              <Text>
                {`z siedzibą w Gdańsku (${
                  seller?.zipcode || building.seller?.zipcode
                }), przy ${
                  seller?.street || building.seller?.street
                }, reprezentowana przez: ${firstName} ${lastName}.  `}
              </Text>
            </Row>
          </PaddingContainer>
        </>
      ) : (
        <>
          <CentredText>
            {`Protokół przekazania ${handleProtocolTitle(reservations)} „${
              building.name
            }” zlokalizowanego przy ${building.location}`}
          </CentredText>
          <PaddingContainer>
            <Row>
              <TextBold>{`Przekazujący: ${
                seller?.name || building.seller?.name
              } `}</TextBold>
              <Text>
                {`z siedzibą w Gdańsku (${
                  seller?.zipcode || building.seller?.zipcode
                }), przy ${
                  seller?.street || building.seller?.street
                }, reprezentowana przez: ${firstName} ${lastName}.  `}
              </Text>
            </Row>
            <Row>
              {user ? (
                <TextBold>{`Odbierający: ${user} `}</TextBold>
              ) : (
                <>
                  <TextBold>{`Odbierający: ${name} `}</TextBold>
                  <Text>
                    {`z siedzibą w ${city} (${zipcode}), przy ${street}, reprezentowana przez: ${representative}.  `}
                  </Text>
                </>
              )}
            </Row>
          </PaddingContainer>
        </>
      )}
    </>
  )
}

export default ProtocolContent
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const Row = styled(Grid)`
  padding: 6px;
`
const CentredText = styled(TextBody1Bold)`
  text-align: center;
  margin: 40px 0 0;
`
const PaddingContainer = styled(Grid)`
  padding: 32px 0;
`
