import gql from 'graphql-tag'

export interface ICarPlateVerification {
  id: string
  createdAt: string
  user: {
    firstName: string
    lastName: string
    login: string
  }
  carPlate: string
  found: boolean
}

export interface IQueryCarPlateVerificationData {
  queryCarPlateVerification: ICarPlateVerification[]
}
export const QUERY_CAR_PLATE_VERIFICATION = gql`
  query queryCarPlateVerification {
    queryCarPlateVerification(order: { desc: created_at }) {
      id
      createdAt: created_at
      user {
        firstName: first_name
        lastName: last_name
        login
      }
      carPlate: car_plate
      found
    }
  }
`
