import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
  date2isoString,
  date2isoStringWithTimezone,
} from '../../utils/formatDate'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { ApolloError, useLazyQuery } from '@apollo/client'
import {
  PARKING_DAILY_USAGE_STAT,
  IStatParkingSubscriptionVars,
  IStatParkingSubscriptionData,
} from '../../graphql/parking/statParkingSubscriptionsDailyUsage'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import DateRangeParkingOccupancyFilter from '../../components/Parking/DateRangeParkingOccupancyFilter'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  date: string
  dateCSV: string
  parking: string
  capacity: number
  sold: number
  used: number
}

const now = new Date()
const start = new Date(now.setDate(now.getDate() - 30))
const end = new Date()

const ParkingOccupancyReport = () => {
  const { t, i18n } = useTranslation(['parking_list'])
  const lang = i18n.language
  const [rangeFrom, setRangeFrom] = useState<Date | null>(start)
  const [rangeTo, setRangeTo] = useState<Date | null>(end)
  const [error, setError] = useState('')

  const invisibleColumns = {
    dateCSV: false,
  }

  const csvHeaders = [
    {
      label: 'Data',
      key: 'dateCSV',
    },
    {
      label: 'Parking',
      key: 'parking',
    },
    {
      label: 'Pojemność',
      key: 'capacity',
    },
    {
      label: 'Sprzedane',
      key: 'sold',
    },
    {
      label: 'Użyte',
      key: 'used',
    },
  ]

  const onCompleted = (data: IStatParkingSubscriptionData) => {
    const { code, status } = data.statParkingSubscriptionsDailyUsage
    if (!status) {
      setError(t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`))
    } else setError('')
  }

  const [parkingDailyUsageStat, { data, loading }] = useLazyQuery<
    IStatParkingSubscriptionData,
    IStatParkingSubscriptionVars
  >(PARKING_DAILY_USAGE_STAT, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      setError(error.message)
    },
    onCompleted,
  })

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: t('date'),
        cell: (value) =>
          dateIso2localeString(value.getValue(), lang, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dateCSV',
        header: t('date'),
      },
      {
        accessorKey: 'parking',
        header: 'Parking',
      },
      {
        accessorKey: 'capacity',
        header: t('capacity'),
      },
      {
        accessorKey: 'sold',
        header: t('sold'),
      },
      {
        accessorKey: 'used',
        header: t('used'),
      },
    ],
    [t, lang]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      data?.statParkingSubscriptionsDailyUsage.stats?.map(
        ({ date, parking, capacity, sold, used }) => ({
          date,
          dateCSV: dateIso2ExcelFormat(date),
          parking,
          capacity,
          sold,
          used,
        })
      ) || []
    )
  }, [data])

  useEffect(() => {
    if (rangeFrom && rangeTo)
      parkingDailyUsageStat({
        variables: {
          from: date2isoStringWithTimezone(new Date(rangeFrom)),
          to: date2isoString(new Date(rangeTo)),
        },
      })
  }, [rangeFrom, rangeTo])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('parking_occupancy_report')}
      </TypographyStyled>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
      >
        <DateRangeParkingOccupancyFilter
          rangeFrom={rangeFrom}
          rangeTo={rangeTo}
          setRangeFrom={setRangeFrom}
          setRangeTo={setRangeTo}
        />
      </Table>
      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default ParkingOccupancyReport

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
