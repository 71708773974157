import gql from 'graphql-tag'
import { PaymentStatus } from '../shared/sharedTypes'

export interface IEvent {
  id: number
  title: string
  startDate: string
}

export interface IEventTicket {
  id: string
  code: string
  resident: boolean
}

export interface IEventOrder {
  id: string
  orderId: string
  createdAt: string
  paymentId: string
  user: {
    firstName: string
    lastName: string
    email: {
      email: string
    }
    tenant: {
      name: string
    }
  }
  service: string
  event: IEvent
  tickets: IEventTicket[]
  amount: number
  paymentStatus: PaymentStatus
  phoneNumber?: string
}

export interface IQueryEventOrdersData {
  queryEventOrders: IEventOrder[]
}

export const QUERY_EVENT_ORDERS = gql`
  query QueryEventOrders {
    queryEventOrders(order: { desc: created_at }) {
      id
      orderId: order_id
      createdAt: created_at
      paymentId: payment_token
      user {
        firstName: first_name
        lastName: last_name
        email {
          email
        }
        tenant {
          name
        }
      }
      event {
        title
        startDate
      }
      service
      tickets {
        id
        code
        resident
      }
      amount
      paymentStatus: payment_status
      phoneNumber: phone_number
    }
  }
`
