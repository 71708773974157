import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Grid, Box } from '@mui/material'
import {
  TextBody2Medium,
  TextBody2Bold,
} from '../../components/Styles/TextCustom'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const LockerTypePicker = ({
  garageLockers,
  lockerroomLockers,
  setGarageLockers,
  setLockerroomLockers,
  availableGarageLockers,
  availableLockerRoomLockers,
}: {
  garageLockers: number
  lockerroomLockers: number
  setGarageLockers: (v: number) => void
  setLockerroomLockers: (v: number) => void
  availableGarageLockers: number
  availableLockerRoomLockers: number
}) => {
  const { t } = useTranslation(['lockers'])

  const handleSubtractItem = (value: number, setValue: (v: number) => void) =>
    value && setValue(value - 1)

  const handleAddItem = (
    value: number,
    setValue: (v: number) => void,
    available: number
  ) => (value || value === 0) && value < available && setValue(value + 1)

  const renderInput = (
    value: number,
    setValue: (v: number) => void,
    available: number
  ) => (
    <InputWrapper>
      <IconButtonStyled
        disabled={available === 0}
        border={available ? themeColors.primary : themeColors.lightGray}
        color="primary"
        onClick={() => handleSubtractItem(value, setValue)}
        data-e2e="subtract-button"
      >
        <FontAwesomeIcon icon={faMinus as IconProp} />
      </IconButtonStyled>
      <NumberWrapper
        sx={{
          backgroundColor: themeColors.white,
          borderRadius: '5px',
        }}
      >
        <TextBody2Medium>{value}</TextBody2Medium>
      </NumberWrapper>
      <IconButtonStyled
        disabled={available === 0}
        border={available ? themeColors.primary : themeColors.lightGray}
        color="primary"
        onClick={() => handleAddItem(value, setValue, available)}
        data-e2e="add-button"
      >
        <FontAwesomeIcon icon={faPlus as IconProp} />
      </IconButtonStyled>
    </InputWrapper>
  )

  return (
    <>
      <Container>
        <Row container justifyContent="center" alignItems="center">
          <Grid item xs={5}>
            <TextBody2Medium>{`${t(
              'garage'
            )} (${availableGarageLockers})`}</TextBody2Medium>
          </Grid>
          <Row item xs={7}>
            {renderInput(
              garageLockers,
              setGarageLockers,
              availableGarageLockers
            )}
          </Row>
        </Row>
        <Row container justifyContent="center" alignItems="center">
          <Grid item xs={5}>
            <TextBody2Medium>{`${t(
              'locker_room'
            )} (${availableLockerRoomLockers})`}</TextBody2Medium>
          </Grid>
          <Row item xs={7}>
            {renderInput(
              lockerroomLockers,
              setLockerroomLockers,
              availableLockerRoomLockers
            )}
          </Row>
        </Row>
      </Container>
      <Row container>
        <Grid item xs={6}></Grid>
        <Row item xs={6}>
          <TextBody2Bold>
            {`${t('total')} ${garageLockers + lockerroomLockers}`}
          </TextBody2Bold>
        </Row>
      </Row>
    </>
  )
}

export default LockerTypePicker

const Container = styled(Grid)`
  border-bottom: 1px solid #b5b5b5;
  padding-bottom: 6px;
`

const Row = styled(Grid)`
  max-width: 500px;
  padding: 5px 0;
`
const NumberWrapper = styled(Box)`
  display: flex;
  width: 75px;
  height: 56px;
  border: 1px solid #b5b5b5;
  justify-content: center;
  align-items: center;
`
const IconButtonStyled = styled(IconButton)<{ border: string }>`
  width: 40px;
  height: 40px;
  margin: 0 16px;
  ${({ border }) => `border: 1px solid ${border};`};
`
const InputWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
