import React, { FC, useState } from 'react'
import { useAppSelector } from '../../redux/store'
import { List, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { faBicycle } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { checkRole } from '../../utils/checkRole'
import { LOCKER_ROLES, LOCKER_ADMIN } from '../../const/permissions'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  MainListItem,
  ListItem,
  CustomListItemText,
  LinkStyled,
} from '../Styles/NavListStyle'

interface ILockersListItemProps {
  isMenuOpen?: boolean
}

const LockersListItem: FC<ILockersListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])
  let { pathname } = useLocation()
  const [isLockersExpanded, setIsLockersExpanded] = useState(false)
  const { roles } = useAppSelector((state) => state.user)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : `/admin/${route}` === pathname

  if (!checkRole(roles, LOCKER_ROLES)) {
    return null
  }

  return (
    <>
      <MainListItem
        onClick={() => setIsLockersExpanded(!isLockersExpanded)}
        data-e2e="navigation-lockers"
      >
        <ListItemIcon>
          <FAIcon icon={faBicycle as IconProp} />
        </ListItemIcon>
        <CustomListItemText primary={t('bike_lockers')} />
        {isLockersExpanded ? <ExpandLess /> : <ExpandMore />}
      </MainListItem>
      <Collapse in={isMenuOpen && isLockersExpanded} timeout="auto">
        <List component="div">
          <LinkStyled to={ROUTES.LOCKER_GROUP_RESERVATIONS}>
            <ListItem
              selected={isSelected(
                ROUTES.LOCKER_GROUP_RESERVATIONS,
                isLockersExpanded
              )}
              data-e2e="navigation-lockers-group-reservations"
            >
              <ListItemText primary={t('group_reservation_list')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.LOCKER_RESERVATIONS}>
            <ListItem
              selected={isSelected(
                ROUTES.LOCKER_RESERVATIONS,
                isLockersExpanded
              )}
              data-e2e="navigation-locker-reservations"
            >
              <ListItemText primary={t('reservation_list')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.LOCKERS}>
            <ListItem
              selected={isSelected(ROUTES.LOCKERS, isLockersExpanded)}
              data-e2e="navigation-locker-list"
            >
              <ListItemText primary={t('lockers_list')} />
            </ListItem>
          </LinkStyled>
          {checkRole(roles, LOCKER_ADMIN) && (
            <>
              <LinkStyled to={ROUTES.LOCKER_PAYMENT_HISTORY}>
                <ListItem
                  selected={isSelected(
                    ROUTES.LOCKER_PAYMENT_HISTORY,
                    isLockersExpanded
                  )}
                  data-e2e="navigation-lockers-payment-history"
                >
                  <ListItemText primary={t('payment_history')} />
                </ListItem>
              </LinkStyled>
              <LinkStyled to={ROUTES.LOCKER_PRODUCTS}>
                <ListItem
                  selected={isSelected(
                    ROUTES.LOCKER_PRODUCTS,
                    isLockersExpanded
                  )}
                  data-e2e="navigation-locker-products"
                >
                  <ListItemText primary={t('product_list')} />
                </ListItem>
              </LinkStyled>
            </>
          )}
          <LinkStyled to={ROUTES.LOCKER_STATISTICS}>
            <ListItem
              selected={isSelected(ROUTES.LOCKER_STATISTICS, isLockersExpanded)}
              data-e2e="navigation-locker-statistics"
            >
              <ListItemText primary={t('locker_statistics')} />
            </ListItem>
          </LinkStyled>
        </List>
      </Collapse>
    </>
  )
}

export default LockersListItem
