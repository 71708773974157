import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { ClickAwayListener } from '@mui/material'
import BaseModal from '../Shared/BaseModal'
import FullScreenLoader from '../Shared/FullScreenLoader'
import DrawerHeader from '../Shared/DrawerHeader'
import { ITableRow } from '../../screens/Tenants/TenantsList'
import LogsContainer from '../Shared/LogsContainer'
import SeosCardForm from './SeosCardForm'
import TenantDataForm from './TenantDataForm'
import AddParkingPoolForm from './AddParkingPoolForm'
import EditParkingPoolForm from './EditParkingPoolForm'
import HIDAccessAccount from './HIDAccessAccount'
import { useAppSelector } from '../../redux/store'
import { Roles } from '../../graphql/shared/sharedTypes'
import {
  GET_TENANT,
  IGetTenantData,
  IGetTenantVars,
} from '../../graphql/tenant/getTenant'
import { useLazyQuery } from '@apollo/client'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import ParkingPoolProfiles from './ParkingPoolProfiles'

const EditTenantForm = ({
  handleClose,
  handleUpdateTenants,
  tenantToEdit,
}: {
  handleClose: () => void
  handleUpdateTenants: () => void
  tenantToEdit: ITableRow
}) => {
  const [dataFormTouched, setDataFormTouched] = useState(false)
  const [seosFormTouched, setSeosFormTouched] = useState(false)
  const [parkingPoolFormTouched, setParkingPoolFormTouched] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)
  const [refetchList, setRefetchList] = useState(false)
  const { roles } = useAppSelector((state) => state.user)
  const { t } = useTranslation(['form', 'btn', 'tenant', 'generic'])
  const { name } = tenantToEdit

  const [getTenant, { data, loading }] = useLazyQuery<
    IGetTenantData,
    IGetTenantVars
  >(GET_TENANT, {
    fetchPolicy: 'no-cache',
    variables: {
      name,
    },
  })

  useEffect(() => {
    getTenant()
  }, [name])

  useEffect(() => {
    if (dataFormTouched || seosFormTouched || parkingPoolFormTouched) {
      setRefetchList(true)
    }
  }, [dataFormTouched, seosFormTouched, parkingPoolFormTouched])

  if (loading || !data) {
    return <FullScreenLoader />
  }

  const { parkingPool, hidConfig } = data.getTenant

  const handleClickAway = () => {
    if (dataFormTouched || seosFormTouched || parkingPoolFormTouched) {
      setOpenClickAwayModal(true)
    } else {
      refetchList && handleUpdateTenants()
      handleClose()
    }
  }

  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
    setOpenConfirmModal(false)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader
            title={`${t('tenant:edit_tenant')}  ${name}`}
            handleClose={handleClickAway}
          />
          <TenantDataForm
            tenantToEdit={data.getTenant}
            openConfirmModal={openConfirmModal}
            setOpenConfirmModal={setOpenConfirmModal}
            openClickAwayModal={openClickAwayModal}
            setOpenClickAwayModal={setOpenClickAwayModal}
            setDataFormTouched={setDataFormTouched}
          />
          <SeosCardForm
            tenantToEdit={data.getTenant}
            setSeosFormTouched={setSeosFormTouched}
            refetch={getTenant}
          />
          {!!data.getTenant.parkingPool ? (
            <EditParkingPoolForm
              tenantToEdit={data.getTenant}
              setParkingPoolFormTouched={setParkingPoolFormTouched}
              refetch={getTenant}
            />
          ) : (
            <AddParkingPoolForm
              refetch={getTenant}
              tenantToEdit={data.getTenant}
              setParkingPoolFormTouched={setParkingPoolFormTouched}
            />
          )}
          <ParkingPoolProfiles
            parkingPool={parkingPool}
            handleUpdateTenants={handleUpdateTenants}
            refetchTenant={getTenant}
          />
          {roles.includes(Roles.hid) && (
            <HIDAccessAccount
              tenantToEdit={tenantToEdit}
              hidConfig={hidConfig}
              refetchTenant={getTenant}
            />
          )}

          <DetailsWrapper>
            <LogsContainer logs={data?.getTenant.logs} />
          </DetailsWrapper>
        </>
      </ClickAwayListener>
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => handleClose()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default EditTenantForm
