import React, { FC, useState } from 'react'
import { useAppSelector } from '../../redux/store'
import { List, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { checkRole } from '../../utils/checkRole'
import { PUSH_ROLES } from '../../const/permissions'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  MainListItem,
  ListItem,
  CustomListItemText,
  LinkStyled,
} from '../Styles/NavListStyle'

interface IPushListItemProps {
  isMenuOpen?: boolean
}

const PushListItem: FC<IPushListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])
  let { pathname } = useLocation()
  const [isPushExpanded, setIsPushExpanded] = useState(false)

  const { roles } = useAppSelector((state) => state.user)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : `/admin/${route}` === pathname

  if (!checkRole(roles, PUSH_ROLES)) {
    return null
  }

  return (
    <>
      <MainListItem
        onClick={() => setIsPushExpanded(!isPushExpanded)}
        data-e2e="navigation-getresponse"
      >
        <ListItemIcon>
          <FAIcon icon={faMessage as IconProp} />
        </ListItemIcon>
        <CustomListItemText primary={'PUSH'} />
        {isPushExpanded ? <ExpandLess /> : <ExpandMore />}
      </MainListItem>
      <Collapse in={isMenuOpen && isPushExpanded} timeout="auto">
        <List component="div">
          <LinkStyled to={ROUTES.SEND_PUSH}>
            <ListItem selected={isSelected(ROUTES.SEND_PUSH)}>
              <ListItemText primary={t('new_push')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.PUSH_LIST}>
            <ListItem selected={isSelected(ROUTES.PUSH_LIST)}>
              <ListItemText primary={t('push_list')} />
            </ListItem>
          </LinkStyled>
        </List>
      </Collapse>
    </>
  )
}

export default PushListItem
