import React, { FC } from 'react'
import { useAppSelector } from '../../redux/store'
import { ListItemIcon } from '@mui/material'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  MainListItem,
  CustomListItemText,
  BadgeStyled,
  LinkStyled,
} from '../Styles/NavListStyle'
import { checkRole } from '../../utils/checkRole'
import { ISSUE_ROLES } from '../../const/permissions'
import { useIsRouteSelected } from '../../hooks/useIsRouteSelected'

interface IMessagesListItemProps {
  userMessagesCount?: number
  isMenuOpen?: boolean
}

const MessagesListItem: FC<IMessagesListItemProps> = ({
  userMessagesCount,
  isMenuOpen,
}) => {
  const { t } = useTranslation(['admin_menu'])
  const { roles } = useAppSelector((state) => state.user)
  const { isSelected } = useIsRouteSelected(ROUTES.MESSAGES, !!isMenuOpen)

  if (!checkRole(roles, ISSUE_ROLES)) {
    return null
  }

  return (
    <MainListItem selected={isSelected} data-e2e="navigation-messages">
      <LinkStyled to={ROUTES.MESSAGES}>
        <ListItemIcon>
          <FAIcon icon={faEnvelope as IconProp} />
        </ListItemIcon>
        <BadgeStyled badgeContent={userMessagesCount} color="error">
          <CustomListItemText primary={t('messages')} />
        </BadgeStyled>
      </LinkStyled>
    </MainListItem>
  )
}

export default MessagesListItem
