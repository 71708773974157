import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TextBody2Medium } from '../Styles/TextCustom'
import BuildingSelect from '../Shared/UncontrolledBuildingSelect'
import LockerTypePicker from '../Lockers/LockerTypePicker'
import { Grid } from '@mui/material'
import { IFormError } from '../../types/FormTypes'
import { ILocker, LockerType } from '../../graphql/lockers/queryLockers'
import { getCurrentReservation } from '../../utils/getCurrentReservation'
import LockerReservationStatusSelect from '../LockerReservation/UncontrolledReservationStatusSelect'
import RentLockerInputs from './RentLockerInputs'
import { ITableRow } from '../LockerReservation/LockerReservationTable'
import LockerNumbersContainer from '../LockerReservation/LockerNumbersContainer'

const RentManyLockersForm = ({
  selectedLockers,
  lockers,
  garageLockers,
  lockerroomLockers,
  setGarageLockers,
  setLockerroomLockers,
  control,
  errors,
  watch,
  building,
}: {
  selectedLockers: ITableRow[]
  lockers: ILocker[]
  garageLockers: number
  lockerroomLockers: number
  setGarageLockers: (v: number) => void
  setLockerroomLockers: (v: number) => void
  control: Object
  errors: IFormError | any
  watch: any
  building: string
}) => {
  const { t } = useTranslation(['lockers'])
  const [availableInGarage, setAvailableInGarage] = useState(0)
  const [availableInLockerRoom, setAvailableInLockerRoom] = useState(0)

  const pickedBuilding = watch('building')

  useEffect(() => {
    if (!selectedLockers.length || !building) {
      let available = lockers.filter(
        ({ reservations }) => !getCurrentReservation(reservations)
      )
      if (pickedBuilding) {
        available = available.filter(
          ({ building }) => building.name === pickedBuilding
        )
      }
      setAvailableInGarage(
        available.filter(({ type }) => type === LockerType.garage).length
      )
      setAvailableInLockerRoom(
        available.filter(({ type }) => type === LockerType.locker_room).length
      )
    }
  }, [pickedBuilding, lockers, selectedLockers, building])

  return (
    <InputsWrapper>
      <BuildingSelect
        control={control}
        errors={errors}
        defaultValue={building}
        disabled={!!building}
      />
      <InputsWrapper>
        <LockerReservationStatusSelect control={control} errors={errors} />
      </InputsWrapper>
      {!!selectedLockers.length && !!building ? (
        <>
          <TextWithPadding>{t('selected_lockers')}:</TextWithPadding>
          <LockerNumbersContainer
            building={building}
            lockers={selectedLockers}
          />
        </>
      ) : (
        <>
          <TextWithPadding>{t('available_lockers')}:</TextWithPadding>
          <LockerTypePicker
            garageLockers={garageLockers}
            setGarageLockers={setGarageLockers}
            lockerroomLockers={lockerroomLockers}
            setLockerroomLockers={setLockerroomLockers}
            availableGarageLockers={availableInGarage}
            availableLockerRoomLockers={availableInLockerRoom}
          />
        </>
      )}

      <RentLockerInputs control={control} errors={errors} />
    </InputsWrapper>
  )
}

export default RentManyLockersForm

const InputsWrapper = styled(Grid)`
  padding: 16px 0;
`
const TextWithPadding = styled(TextBody2Medium)`
  padding: 16px 0;
`
