import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip, Grid, Menu, MenuItem } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faMessage } from '@fortawesome/free-solid-svg-icons'
import { ITableRow } from '../Lockers/LockerTable'
import { LockerStatus } from '../../graphql/lockers/queryLockers'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const LockerTableActions = ({
  data,
  setOpenedProtocol,
  setPickUpProtocol,
}: {
  data: ITableRow
  setOpenedProtocol: (v: ITableRow[]) => void
  setPickUpProtocol: (v: boolean) => void
}) => {
  const { t } = useTranslation(['lockers'])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleOpenProtocol = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setOpenedProtocol([data])
    handleClose(event)
  }
  const handleOpenPickUpProtocol = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setOpenedProtocol([data])
    setPickUpProtocol(true)
    handleClose(event)
  }

  return (
    <>
      <ActionsWrapper container direction="row" justifyContent="space-between">
        <Grid item>
          {!!data.notes && (
            <Tooltip title={data.notes}>
              <IconButton size="medium">
                <FontAwesomeIcon icon={faMessage as IconProp} size="sm" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          {data.status !== LockerStatus.available && (
            <IconButton
              size="medium"
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <FontAwesomeIcon icon={faEllipsis as IconProp} size="sm" />
            </IconButton>
          )}
        </Grid>
      </ActionsWrapper>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOpenProtocol}>
          {t('print_transfer_protocol')}
        </MenuItem>
        <MenuItem onClick={handleOpenPickUpProtocol}>
          {t('print_pickup_protocol')}
        </MenuItem>
      </Menu>
    </>
  )
}

export default LockerTableActions

const ActionsWrapper = styled(Grid)`
  padding-right: 8px;
  width: 80px;
`
