import gql from 'graphql-tag'
import { IPayment, ILog } from '../shared/sharedTypes'

export enum ParkingSubscriptionStatus {
  active = 'active',
  cancelled = 'cancelled',
  error = 'error',
  new = 'new',
  past = 'past',
  pending = 'pending',
}
export interface IParkingTicket {
  id: string
  saleId: string
  validFrom: string
  validTo: string
  carPlate: string
  carPlate2: string
  payment: IPayment
  reservations: {
    place: {
      name: string
      shared: boolean
      info: {
        message: string
      }
    }
  }[]
}
export interface ISubscription {
  id: string
  status: ParkingSubscriptionStatus
  amanoCardNo: number
  validFrom: string
  validTo: string
  createdAt: string
  carPlate: string
  carPlate2: string
  parkingPass: {
    name: string
    nameEn: string
    parking: {
      name: string
      operator: string
    }
    tenant: {
      name: string
    }
  }
  activeParkingTicket: IParkingTicket
  pendingParkingTicket: IParkingTicket
  logs: ILog[]
  tickets: IParkingTicket[]
  user: {
    login: string
    email: {
      email: string
    }
    tenant: {
      name: string
    }
  }
}

export interface IQuerySubscriptionVars {
  minValidTo: string
}

export interface IQuerySubscriptionData {
  subscriptions: ISubscription[]
}

export const QUERY_PARKING_SUBSCRIPTIONS = gql`
  query queryParkingSubscription($minValidTo: DateTime) {
    subscriptions: queryParkingSubscription(
      filter: { valid_to: { ge: $minValidTo } }
      order: { desc: created_at }
    ) {
      id
      status
      validFrom: valid_from
      validTo: valid_to
      createdAt: created_at
      carPlate: car_plate
      carPlate2: car_plate_2
      parkingPass: parking_pass {
        name
        nameEn: name_en
        parking {
          operator
        }
      }
      activeParkingTicket: active_parking_ticket {
        payment {
          id
          amount
        }
      }
      pendingParkingTicket: pending_parking_ticket {
        payment {
          id
          amount
        }
      }
      logs(order: { desc: created_at }) {
        message
        createdAt: created_at
        parameters {
          name
          value
          type
        }
      }
      tickets: parking_tickets(order: { desc: valid_to }) {
        id
        saleId: sale_id
        validFrom: valid_from
        validTo: valid_to
        carPlate: car_plate
        carPlate2: car_plate_2
        payment {
          id
          sessionId: session_id
          amount
          createdAt: created_at
          status
          externalId: external_order_id
          logs(order: { desc: created_at }) {
            message
            createdAt: created_at
          }
        }
        reservations @cascade(fields: "place") {
          place(filter: { shared: false }) {
            name
            shared
            info {
              message
            }
          }
        }
      }
      user {
        login
        email {
          email
        }
        tenant {
          name
        }
      }
    }
  }
`
