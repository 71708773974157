import styled from 'styled-components'
import { Grid } from '@mui/material'
import {
  TextBody1Bold,
  TextBody2Medium,
  TextBody2Bold,
} from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { Row } from '../../screens/Parking/SoldSubscriptionList'
import { dateIso2localeString } from '../../utils/formatDate'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { getSubscriptionStatusColor } from '../../utils/getStatusColor'
import ParkingTicketDetails from './ParkingTicketDetails'
import DrawerHeader from '../Shared/DrawerHeader'
import LogsContainer from '../Shared/LogsContainer'
import SubscriptionActions from './SubscriptionActions'
import {
  GET_PARKING_SUBSCRIPTION,
  IGetParkingSubscriptionData,
  IGetParkingSubscriptionVars,
} from '../../graphql/parking/getParkingSubscription'
import { useQuery } from '@apollo/client'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import EditTicketCarPlates from './EditTicketCarPlates'

export const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}

const SubscriptionDetails = ({
  data,
  closeDetails,
  refetch,
}: {
  data?: Row
  closeDetails: () => void
  refetch: () => void
}) => {
  const { t, i18n } = useTranslation(['sold_parking_items'])
  const lang = i18n.language

  const { data: subscription, refetch: getSubscription } = useQuery<
    IGetParkingSubscriptionData,
    IGetParkingSubscriptionVars
  >(GET_PARKING_SUBSCRIPTION, {
    variables: {
      id: data?.id,
    },
    fetchPolicy: 'no-cache',
  })

  if (!data) {
    return <FullScreenLoader />
  }

  const {
    id,
    productName,
    validFrom,
    validTo,
    status,
    login,
    email,
    tenant,
    carPlates,
    tickets,
    createdAt,
    operator,
  } = data

  const subsStatus = subscription?.getParkingSubscription.status || status

  const details = [
    { label: t('product_id'), value: id },
    { label: t('product_name'), value: productName },
    {
      label: t('dates'),
      value: `${dateIso2localeString(
        validFrom,
        lang,
        options
      )} - ${dateIso2localeString(validTo, lang, options)}`,
    },
    { label: 'Login', value: login },
    { label: 'Email', value: email },
    { label: t('tenant'), value: tenant },
    { label: t('car_plates'), value: carPlates },
    {
      label: t('created_at'),
      value: dateIso2localeString(createdAt, lang, options),
    },
  ]

  const allowSync = operator === 'Amano'

  const handleCloseDetails = () => {
    refetch()
    closeDetails()
  }

  return (
    <>
      <DrawerHeader
        title={t('details_title')}
        handleClose={handleCloseDetails}
      />
      <DetailsWrapper>
        {details.map(
          ({ label, value }) =>
            value && (
              <RowContainer key={label}>
                <Text>{`${label}: `}</Text>
                <TextBold>{value}</TextBold>
              </RowContainer>
            )
        )}
        <RowContainer>
          <Text>{`${t('status')}: `}</Text>
          <Status color={getSubscriptionStatusColor(subsStatus)}>
            {t(`parking_subscription_status:${subsStatus}`)}
          </Status>
        </RowContainer>
        <LogsContainer logs={subscription?.getParkingSubscription?.logs} />
      </DetailsWrapper>
      <TextBody1Bold>{t('related_tickets_and_payments')}</TextBody1Bold>
      {tickets.map((ticket) => (
        <ParkingTicketDetails ticket={ticket} key={ticket.id} />
      ))}
      <EditTicketCarPlates data={data} refetch={getSubscription} />
      <SubscriptionActions
        id={id}
        refetch={getSubscription}
        status={subsStatus}
        allowSync={allowSync}
      />
    </>
  )
}

export default SubscriptionDetails

const RowContainer = styled(Grid)`
  padding: 2px;
`
const Status = styled(TextBody2Bold)`
  text-transform: uppercase;
  display: inline;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
