import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip, Grid, Menu, MenuItem } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faMessage } from '@fortawesome/free-solid-svg-icons'
import { ITableRow } from './LockerReservationTable'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const ReservationTableActions = ({
  data,
  setRentLockerManually,
  setOpenedProtocol,
  setPickUpProtocol,
}: {
  data: ITableRow
  setRentLockerManually?: (v: ITableRow) => void
  setOpenedProtocol: (v: ITableRow[]) => void
  setPickUpProtocol: (v: boolean) => void
}) => {
  const { t } = useTranslation(['lockers'])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleRentManually = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setRentLockerManually && setRentLockerManually(data)
    handleClose(event)
  }

  const handleOpenProtocol = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setOpenedProtocol([data])
    handleClose(event)
  }
  const handleOpenPickUpProtocol = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setOpenedProtocol([data])
    setPickUpProtocol(true)
    handleClose(event)
  }

  return (
    <>
      <ActionsWrapper container direction="row" justifyContent="space-between">
        <Grid item>
          {!!data.notes && (
            <Tooltip title={data.notes}>
              <IconButton size="medium">
                <FontAwesomeIcon icon={faMessage as IconProp} size="sm" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          <IconButton
            size="medium"
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            data-e2e="reservation-actions-button"
          >
            <FontAwesomeIcon icon={faEllipsis as IconProp} size="sm" />
          </IconButton>
        </Grid>
      </ActionsWrapper>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {data.reservationStatus === ReservationStatus.available && (
          <MenuItem onClick={handleRentManually}>{t('rent_manually')}</MenuItem>
        )}
        {data.reservationStatus !== ReservationStatus.available && (
          <MenuItem onClick={handleOpenProtocol}>
            {t('print_transfer_protocol')}
          </MenuItem>
        )}
        {data.reservationStatus !== ReservationStatus.available && (
          <MenuItem onClick={handleOpenPickUpProtocol}>
            {t('print_pickup_protocol')}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default ReservationTableActions

const ActionsWrapper = styled(Grid)`
  padding-right: 8px;
  width: 80px;
`
