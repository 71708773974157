import { forwardRef } from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import {
  TextBody2Medium,
  TextBody2Bold,
  TextH6Bold,
  TextBody2,
} from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { date2localeString } from '../../utils/formatDate'
import { useTranslation } from 'react-i18next'
import { ITableRow } from '../Lockers/LockerTable'
import { IBuilding } from '../../graphql/lockers/queryLockerGroupReservation'
import ProtocolContent from './ProtocolContent'
import { ICompany } from '../../graphql/shared/queryCompany'
import { FormData } from './GroupReservationSummary'
export interface IProtocolProps {
  tenantData: FormData
  reservations: ITableRow[] | any[]
  building: IBuilding
  pickUp?: boolean
  user?: string
  seller?: ICompany
  noShower?: boolean
}

const Protocol = forwardRef<HTMLDivElement, IProtocolProps>((props, ref) => {
  const { tenantData, reservations, building, pickUp, user, seller, noShower } =
    props
  const { t, i18n } = useTranslation(['protocol'])
  const lang = i18n.language

  const now = date2localeString(new Date(), lang, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  const sortedReservations = reservations.sort((a, b) =>
    a.lockerNumber > b.lockerNumber ? 1 : -1
  )

  return (
    <Page ref={ref}>
      <Grid container>
        <Grid item xs={3}></Grid>
        <RightContainer item xs={9}>
          <Container>
            <TextH6Bold>{'OLIVIA BUSINESS CENTRE'}</TextH6Bold>
          </Container>
          <TextBody2>{`${t('date')}: ${now}`}</TextBody2>
        </RightContainer>
      </Grid>
      <ProtocolContent
        tenantData={tenantData}
        reservations={reservations}
        building={building}
        pickUp={pickUp}
        user={user}
        seller={seller}
      />

      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>{'L.p.'}</TableCell>
            <TableCell align="left">{t('locker_no')}</TableCell>
            <TableCell align="left">{t('key_no')}</TableCell>
            <TableCell align="left">{t('transponder_no')}</TableCell>
            <TableCell align="left">{t('tenant')}</TableCell>
            <TableCell align="left">{t('key_number')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedReservations.map(({ name, key, transponder }, i) => (
            <TableRow
              key={name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {i + 1}
              </TableCell>
              <TableCell align="left">{name}</TableCell>
              <TableCell align="left">{key}</TableCell>
              <TableCell align="left">{noShower ? '-' : transponder}</TableCell>
              <TableCell align="left">
                {!!user ? user : tenantData.name}
              </TableCell>
              <TableCell align="left">{2}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!pickUp && (
        <PaddingContainer>
          <Row>
            <Text>{t('point_1')}</Text>
          </Row>
          <Row>
            <Text>{t('point_2')}</Text>
          </Row>
        </PaddingContainer>
      )}

      <PaddingGrid container>
        <Grid item xs={6} container justifyContent="center" alignItems="center">
          <TextBold>{t('providing')}</TextBold>
        </Grid>
        <Grid item xs={6} container justifyContent="center" alignItems="center">
          <TextBold>{t('receiver')}</TextBold>
        </Grid>
      </PaddingGrid>
    </Page>
  )
})

export default Protocol

const Page = styled.div`
  padding: 40px;
  overflow: hidden;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const Row = styled(Grid)`
  padding: 6px;
`
const RightContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const Container = styled(Grid)`
  border-bottom: 1px solid ${themeColors.lightGray};
  margin-bottom: 8px;
`
const PaddingContainer = styled(Grid)`
  padding: 32px 0 0;
`
const PaddingGrid = styled(Grid)`
  padding: 32px 0 0;
`
