import styled from 'styled-components'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { ITableRow } from '../../screens/Lockers/LockerPaymentHistory'
import { dateIso2localeString } from '../../utils/formatDate'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { getPaymentStatusColor } from '../../utils/getStatusColor'
import DrawerHeader from '../Shared/DrawerHeader'
import LogsContainer from '../Shared/LogsContainer'
import AddLogForm from '../Shared/AddLogForm'
import {
  GET_PAYMENT_LOGS,
  IGetPaymentLogsData,
  IGetPaymentLogsVars,
} from '../../graphql/shared/getPaymentLogs'
import { useQuery } from '@apollo/client'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'

export const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}

const LockerPaymentDetails = ({
  data,
  closeDetails,
  refetch,
}: {
  data?: ITableRow
  closeDetails: () => void
  refetch: () => void
}) => {
  const { t, i18n } = useTranslation(['payment_history'])
  const lang = i18n.language

  const { data: paymentLogs, refetch: getPaymentLogs } = useQuery<
    IGetPaymentLogsData,
    IGetPaymentLogsVars
  >(GET_PAYMENT_LOGS, {
    variables: {
      id: data?.paymentId,
    },
  })

  if (!data) {
    return <FullScreenLoader />
  }

  const {
    paymentId,
    sessionId,
    productName,
    validFrom,
    validTo,
    status,
    login,
    tenantName,
    createdAt,
    price,
    seller,
    fv,
    buyerName,
    nip,
    street,
    zipcode,
    city,
    country,
    invoiceEmail,
  } = data

  const details = [
    { label: t('payment_id'), value: sessionId },
    { label: t('product_name'), value: productName },
    { label: t('seller_company'), value: seller?.name },
    { label: t('seller_nip'), value: seller?.nip },
    {
      label: t('dates'),
      value: `${dateIso2localeString(
        validFrom,
        lang,
        options
      )} - ${dateIso2localeString(validTo, lang, options)}`,
    },
    { label: 'Login', value: login },
    { label: t('tenant_name'), value: tenantName },
    {
      label: t('created_at'),
      value: dateIso2localeString(createdAt, lang, options),
    },
    { label: t('price'), value: price },
  ]

  const invoiceData = [
    { value: buyerName },
    { value: nip },
    { value: street },
    { value: zipcode },
    { value: city },
    { value: country },
    { value: invoiceEmail },
  ]

  return (
    <>
      <DrawerHeader title={t('details_title')} handleClose={closeDetails} />
      <DetailsWrapper>
        {details.map(
          ({ label, value }) =>
            value && (
              <Row key={label}>
                <Text>{`${label}: `}</Text>
                <TextBold>{value}</TextBold>
              </Row>
            )
        )}
        <Row>
          <Text>{'Status: '}</Text>
          <Status color={getPaymentStatusColor(status)}>
            {t(`payment_status:${status}`)}
          </Status>
        </Row>
        <Row>
          <Text>{`${t('invoice')}: `}</Text>
          <TextBold>{fv ? t('generic:yes') : t('generic:no')}</TextBold>
        </Row>
        {fv && (
          <Container>
            <Text>{`${t('invoice_data')}: `}</Text>
            {invoiceData.map(
              ({ value }, i) =>
                value && <TextBody2Bold key={i}>{value}</TextBody2Bold>
            )}
          </Container>
        )}
      </DetailsWrapper>

      <DetailsWrapper>
        <AddLogForm
          id={paymentId}
          refetch={refetch}
          closeDetails={closeDetails}
          getLogs={getPaymentLogs}
        />
      </DetailsWrapper>
      {!!paymentLogs && (
        <DetailsWrapper>
          <LogsContainer logs={paymentLogs.getPayment.logs} />
        </DetailsWrapper>
      )}
    </>
  )
}

export default LockerPaymentDetails

const Row = styled(Grid)`
  padding: 2px;
  max-width: 500px;
`
const Status = styled(TextBody2Bold)`
  text-transform: uppercase;
  display: inline;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const Container = styled(Grid)`
  padding: 2px;
`
