import { FC } from 'react'
import { ROUTES } from '../../const/routes'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { themeColors } from '../../const/colors'
import NewTabLink from '../Shared/NewTabLink'

const UserLinks: FC<{ login: string }> = ({ login }) => {
  const { t } = useTranslation(['user_search'])

  const options = [
    {
      label: t('garden_tickets'),
      route: ROUTES.GARDEN_TICKETS,
    },
    {
      label: t('parking_subcriptions'),
      route: ROUTES.PARKING_PAYMENT_HISTORY,
    },
    {
      label: t('locker_reservations'),
      route: ROUTES.LOCKER_PAYMENT_HISTORY,
    },
    {
      label: t('user_messages'),
      route: ROUTES.MESSAGES,
    },
  ]

  return (
    <BlueContainer>
      {options.map(({ label, route }, i) => (
        <NewTabLink text={label} path={`/admin/${route}/${login}`} key={i} />
      ))}
    </BlueContainer>
  )
}

export default UserLinks

const BlueContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 20px;
  margin: 16px 0;
  height: 100%;
`
