import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import { ITableRow } from './PlacesTable'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import {
  UNASSIGN_PLACES_FROM_PARKING_POOL,
  IUnassignPlacesFromParkingPoolData,
  IUnassignPlacesFromParkingPoolVars,
} from '../../graphql/parking/unassignPlacesFromParkingPool'
import {
  IGetTenantData,
  IGetTenantVars,
  GET_TENANT_PARKING_POOL,
} from '../../graphql/tenant/getTenantParkingPool'
import TooltipButton from '../../components/Shared/TooltipButton'
import BaseModal from '../../components/Shared/BaseModal'
import { ModalContent } from '../../components/Styles/CustomElementsStyled'
import ErrorContainer from '../Shared/ErrorContainer'

const UnassignPlaceFromParkingPool = ({
  tenant,
  places,
  refetch,
}: {
  tenant: string
  places: ITableRow[]
  refetch: () => void
}) => {
  const { t } = useTranslation(['parking_places'])
  const { data } = useQuery<IGetTenantData, IGetTenantVars>(
    GET_TENANT_PARKING_POOL,
    {
      variables: { name: tenant },
      fetchPolicy: 'no-cache',
    }
  )
  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)
  const ids = places.map(({ id }) => id)

  const onCompleted = (data: IUnassignPlacesFromParkingPoolData) => {
    if (data.unassignPlacesFromParkingPool.status) {
      successNotify(`${t('generic:success_message')}`)
      refetch()
    } else {
      setErrorMessage(t(`code:${data.unassignPlacesFromParkingPool.code}`))
    }
    setOpen(false)
  }

  const [unassignPlacesFromParkingPool] = useMutation<
    IUnassignPlacesFromParkingPoolData,
    IUnassignPlacesFromParkingPoolVars
  >(UNASSIGN_PLACES_FROM_PARKING_POOL, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  if (!data) {
    return null
  }

  const handleUnassignPlaces = async () =>
    await unassignPlacesFromParkingPool({
      variables: { pool: data?.getTenant.parkingPool.id, places: ids },
    })

  return (
    <Wrapper>
      <TooltipButton
        onClick={() => setOpen(true)}
        disabled={!places.length || !tenant}
        hint={t('select_places_hint')}
        data-e2e="unassign-places"
      >
        {t('unassign_places')}
      </TooltipButton>
      <BaseModal
        confirmText={'btn:confirm'}
        open={open}
        handleClose={() => setOpen(false)}
        handleAccept={handleUnassignPlaces}
      >
        <ModalContent>{t('confirm_modal_content')}</ModalContent>
      </BaseModal>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
    </Wrapper>
  )
}

export default UnassignPlaceFromParkingPool

const Wrapper = styled(Grid)`
  padding: 16px 0;
`
