import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DrawerHeader from '../Shared/DrawerHeader'
import DrawerButtons from '../Shared/DrawerButtons'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import styled from 'styled-components'
import Input from '../Shared/Input'
import PlaceReservationStatusSelect from './PlaceReservationStatusSelect'
import { getParkingPlaceReservationStatusColor } from '../../utils/getStatusColor'
import { date2localeString } from '../../utils/formatDate'
import { ITableRow } from './PlaceReservationsTable'
import { themeColors } from '../../const/colors'
import TenantSelect from '../Shared/TenantSelect'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'

const EditPlaceReservation = ({
  closeDetails,
  parking,
  status,
  rangeFrom,
  rangeTo,
  places,
}: {
  closeDetails: () => void
  parking: string
  status: ReservationStatus | ''
  rangeFrom: Date | null
  rangeTo: Date | null
  places: ITableRow[]
}) => {
  const { t, i18n } = useTranslation(['parking_places'])
  const lang = i18n.language
  const [newStatus, setNewStatus] = useState<ReservationStatus | ''>(status)

  const formatDate = (date: Date) =>
    date2localeString(date, lang, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data: any) => console.log(data, newStatus)

  const details = [
    { label: 'Parking', value: parking },
    { label: 'Status', value: t(status) },
    {
      label: t('lease_term'),
      value:
        rangeFrom &&
        `${formatDate(rangeFrom)} - ${
          rangeTo ? formatDate(rangeTo) : t('unlimited')
        }`,
    },
  ]

  return (
    <>
      <DrawerHeader
        title={t('change_places_status')}
        handleClose={closeDetails}
      />
      {details.map(({ label, value }) =>
        value ? (
          <Row key={label}>
            <Text>{`${label}: `}</Text>
            <TextBold
              color={
                label === 'Status' && status !== ''
                  ? getParkingPlaceReservationStatusColor(status)
                  : undefined
              }
            >
              {value}
            </TextBold>
          </Row>
        ) : null
      )}
      <Row>
        <TextBody2Bold color={themeColors.primary}>{`${places.length} ${t(
          'generic:selected'
        )}`}</TextBody2Bold>
      </Row>
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <TextWithPadding>{t('new_status')}:</TextWithPadding>
        <PlaceReservationStatusSelect
          value={newStatus}
          setValue={setNewStatus}
        />
        <TextWithPadding>{t('comment')}:</TextWithPadding>
        <InputStyled
          label={t('comment')}
          name={'comment'}
          control={control}
          errors={errors}
          multiline
          rows={4}
        />
        <TextWithPadding>{t('company')}:</TextWithPadding>
        <TenantSelect control={control} errors={errors} />
        <DrawerButtons
          handleAccept={handleSubmit(onSubmit)}
          handleCancel={closeDetails}
        />
      </FormStyled>
    </>
  )
}

export default EditPlaceReservation

const Row = styled(Grid)`
  padding: 6px;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const InputStyled = styled(Input)`
  max-width: 500px;
`
const TextWithPadding = styled(TextBody2Medium)`
  padding: 16px 0;
`
const FormStyled = styled.form`
  padding: 16px 0 0;
`
