import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import {
  IQueryUserRolesData,
  QUERY_USER_ROLES_LIST,
} from '../../graphql/users/queryUserRoles'
import UserRolesTable from '../../components/UserDetails/UserRolesTable'
import RoleSelect from '../../components/Shared/RoleSelect'
import { IUser } from '../../graphql/users/queryUserList'

const UserRolesList = () => {
  const { t } = useTranslation(['columns', 'user_details', 'admin_menu'])
  const [role, setRole] = useState('')
  const [filteredData, setFilteredData] = useState<IUser[]>([])

  const { data, loading, refetch } = useQuery<IQueryUserRolesData>(
    QUERY_USER_ROLES_LIST,
    {
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    if (data) {
      let items = data.queryUser
      if (!!role && role !== 'none') {
        items = items.filter(({ roles }) =>
          roles.map((item) => item.name).includes(role)
        )
      }
      setFilteredData(items)
    }
  }, [data, role])

  if (loading || !data) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('admin_menu:user_roles_list')}
      </TypographyStyled>
      <UserRolesTable data={filteredData} role={role} refetch={refetch}>
        <RoleSelect value={role} setValue={setRole} />
      </UserRolesTable>
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`

export default UserRolesList
