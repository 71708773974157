import { FC, ReactNode, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import {
  Button,
  ButtonBase,
  Divider,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material'
import { ChevronLeft, ChevronRight, AccountCircle } from '@mui/icons-material'
import NavList from './NavList'
import { themeColors } from '../../const/colors'
import { useAppSelector } from '../../redux/store'
import { UserActionTypes } from '../../redux/user/types/UserActionTypes'
import { useTranslation } from 'react-i18next'
import { ToolbarPlaceholder } from '../Styles/ToolbarPlaceholder'
import PageContentContainer from '../PageContentContainer'
import { styled as styledMaterial } from '@mui/material/styles'
import { TextBody2, TextBody2Medium } from '../../components/Styles/TextCustom'
import {
  GET_APP_VERSION,
  IGetAppVersionData,
} from '../../graphql/getAppVersion'
import { useQuery } from '@apollo/client'

interface INavigationProps {
  tenantRequestCount?: number
  emailVerificationRequestCount?: number
  userMessagesCount?: number
  accessCardIssuesCount?: number
  children: ReactNode
}

interface ICustomWindow extends Window {
  TAG?: string
}
declare var window: ICustomWindow

const AppBarStyled = styledMaterial(AppBar)({
  zIndex: 1500,
  backgroundColor: themeColors.white,
  height: 64,
})

const Navigation: FC<INavigationProps> = ({
  tenantRequestCount,
  emailVerificationRequestCount,
  userMessagesCount,
  accessCardIssuesCount,
  children,
}) => {
  const { t } = useTranslation(['admin_menu'])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(true)
  const { fullName } = useAppSelector((state) => state.user)

  const { data } = useQuery<IGetAppVersionData>(GET_APP_VERSION, {
    fetchPolicy: 'no-cache',
  })

  const logout = () => dispatch({ type: UserActionTypes.LOGOUT })

  return (
    <NavRoot>
      <AppBarStyled position="fixed" enableColorOnDark color="default">
        <ToolbarStyled>
          <Grid container spacing={6}>
            <Grid item>
              <ButtonBase onClick={() => navigate('/admin')}>
                <Logo>
                  My <span>Olivia</span>
                  <TextVersion>{window?.TAG || 'local'}</TextVersion>
                </Logo>
              </ButtonBase>
            </Grid>
            <Grid item>
              <TextBody2Medium color={themeColors.primary}>
                {t('current_version')}
              </TextBody2Medium>
              <Grid container>
                <Grid item xs={5}>
                  <Text>{'iOS: '}</Text>
                  <TextBold>
                    {data?.iosVersion?.value?.split('b')[0] || 'brak'}
                  </TextBold>
                </Grid>
                <Grid item xs={7}>
                  <Text>{'Android: '}</Text>
                  <TextBold>
                    {data?.androidVersion?.value?.split('b')[0] || 'brak'}
                  </TextBold>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button color="primary" onClick={logout} data-e2e="button-logout">
                {t('btn:logout')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                href="https://myolivia.blob.core.windows.net/myolivia/docs/faq/html/admin_faq_pl_v1.html"
                target="_blank"
              >
                FAQ
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" startIcon={<AccountCircle />}>
                {fullName}
              </Button>
            </Grid>
          </Grid>
        </ToolbarStyled>
      </AppBarStyled>
      <DrawerStyled variant="permanent" isOpen={isMenuOpen}>
        <ToolbarPlaceholder />
        <NavList
          isMenuOpen={isMenuOpen}
          tenantRequestCount={tenantRequestCount}
          emailVerificationRequestCount={emailVerificationRequestCount}
          userMessagesCount={userMessagesCount}
          accessCardIssuesCount={accessCardIssuesCount}
        />
        <Divider />
        <List disablePadding>
          <ListItem onClick={() => setIsMenuOpen((prev) => !prev)}>
            <ListItemIcon>
              {isMenuOpen ? <ChevronLeft /> : <ChevronRight />}
            </ListItemIcon>
            <ListItemText primary={t('minimize_menu')} />
          </ListItem>
        </List>
      </DrawerStyled>
      <PageContent>{children}</PageContent>
    </NavRoot>
  )
}

const NavRoot = styled.div`
  display: flex;
`
const Text = styled(TextBody2)`
  display: inline;
`
const TextBold = styled(TextBody2Medium)`
  display: inline;
`

const ToolbarStyled = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`
const Logo = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  color: black;
  & > span {
    font-weight: 800;
    color: ${themeColors.primary};
  }
`
const TextVersion = styled.p`
  font-size: 14px;
  color: gray;
  margin: 0;
`
// warning fix "React does not recognize the "isOpen" prop on a DOM element."
const DrawerStyled = styled(({ isOpen, ...props }) => <Drawer {...props} />)<{
  isOpen?: boolean
}>`
  flex-shrink: 0;
  z-index: 1;
  &,
  & > .MuiDrawer-paper {
    width: ${({ isOpen }) => (isOpen ? '18rem' : '4.375rem')};
    overflow-x: ${({ isOpen }) => (isOpen ? 'initial' : 'hidden')};
  }
`
const PageContent = styled(PageContentContainer)`
  padding: 2.1875rem 1.5625rem;
`

export default Navigation
