import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { ApolloError, useQuery } from '@apollo/client'
import {
  QUERY_LOCKER_ORDERS,
  IQueryLockerOrdersData,
} from '../../graphql/lockers/queryLockerOrders'
import { useLocation } from 'react-router-dom'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { getPaymentStatusColor } from '../../utils/getStatusColor'
import { PaymentStatus, ILog } from '../../graphql/shared/sharedTypes'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import LockerPaymentDetails from '../../components/Lockers/LockerPaymentDetails'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import { useParams } from 'react-router-dom'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  id: string
  paymentId: string
  sessionId: string
  createdAt: string
  createdAtCSV: string
  validFrom: string
  validFromCSV: string
  validTo: string
  validToCSV?: string
  productName: string
  building?: string
  fv: boolean
  fvCSV: string
  invoiceEmail: string
  login: string
  fullName: string
  nip?: string
  buyerName?: string
  zipcode?: string
  street?: string
  city?: string
  country?: string
  tenantName?: string
  status: PaymentStatus
  price?: string
  priceCSV?: string
  logs: ILog[]
  sandbox?: string
  seller?: {
    name: string
    nip: string
  }
}
interface stateType {
  prop: {
    paymentId?: string
  }
}

const PaymentHistory = () => {
  const { t, i18n } = useTranslation(['payment_history'])
  const location = useLocation()
  const state: stateType = location.state as stateType
  const { usr } = useParams()
  const paymentId = state?.prop?.paymentId
  const lang = i18n.language
  const [openedDetails, setOpenedDetails] = useState<Row>()
  const [error, setError] = useState('')

  const invisibleColumns = {
    id: false,
    createdAtCSV: false,
    validFromCSV: false,
    validToCSV: false,
    fv: false,
    fvCSV: false,
    nip: false,
    buyerName: false,
    zipcode: false,
    street: false,
    city: false,
    country: false,
    tenantName: false,
    priceCSV: false,
  }

  const csvHeaders = [
    {
      label: t('payment_id'),
      key: 'id',
    },
    {
      label: t('payment_date'),
      key: 'createdAtCSV',
    },
    {
      label: t('lease_from'),
      key: 'validFromCSV',
    },
    {
      label: t('lease_to'),
      key: 'validToCSV',
    },
    {
      label: t('product_name'),
      key: 'productName',
    },
    {
      label: t('building'),
      key: 'building',
    },
    {
      label: 'FV',
      key: 'fvCSV',
    },
    {
      label: 'NIP',
      key: 'nip',
    },
    {
      label: t('columns:full_name'),
      key: 'fullName',
    },
    {
      label: t('zipcode'),
      key: 'zipcode',
    },
    {
      label: t('street'),
      key: 'street',
    },
    {
      label: t('city'),
      key: 'city',
    },
    {
      label: t('country'),
      key: 'country',
    },
    {
      label: t('locker_number'),
      key: 'lockerNumber',
    },
    {
      label: t('tenant_name'),
      key: 'tenantName',
    },
    {
      label: `${t('payment_value_gross')} (PLN)`,
      key: 'priceCSV',
    },
    {
      label: 'Status',
      key: 'status',
    },
  ]

  const { data, refetch, loading } = useQuery<IQueryLockerOrdersData>(
    QUERY_LOCKER_ORDERS,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => {
        setError(error.message)
      },
    }
  )

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      { accessorKey: 'id', header: t('payment_id') },
      {
        accessorKey: 'createdAt',
        header: t('payment_date'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'createdAtCSV',
        header: t('payment_date_csv'),
      },

      {
        accessorKey: 'validFrom',
        header: t('lease_from'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validFromCSV',
        header: t('lease_from'),
      },
      {
        accessorKey: 'validTo',
        header: t('lease_to'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validToCSV',
        header: t('lease_to'),
      },
      {
        accessorKey: 'productName',
        header: t('product_name'),
      },
      {
        accessorKey: 'status',
        header: t('payment_status'),
        cell: (value) => t(`payment_status:${value.getValue()}`),
      },
      {
        accessorKey: 'building',
        header: t('building'),
      },
      {
        accessorKey: 'fv',
        header: t('fv'),
        cell: (value): string =>
          value.getValue() ? t('bool:yes') : t('bool:no'),
      },
      {
        accessorKey: 'fvCSV',
        header: t('fv'),
      },
      {
        accessorKey: 'fullName',
        header: t('columns:full_name'),
      },
      {
        accessorKey: 'login',
        header: t('columns:login'),
      },
      {
        accessorKey: 'nip',
        header: t('nip'),
      },
      {
        accessorKey: 'buyerName',
        header: t('name'),
      },
      {
        accessorKey: 'zipcode',
        header: t('zipcode'),
      },
      {
        accessorKey: 'street',
        header: t('street'),
      },
      {
        accessorKey: 'city',
        header: t('city'),
      },
      {
        accessorKey: 'country',
        header: t('country'),
      },
      {
        accessorKey: 'tenantName',
        header: t('tenant_name'),
      },
      {
        accessorKey: 'price',
        header: t('payment_value_gross'),
      },
      {
        accessorKey: 'priceCSV',
        header: t('payment_value_gross'),
      },
      {
        accessorKey: 'sandbox',
        header: 'Sandbox',
        meta: {
          filterVariant: 'select',
          options: [
            { label: 'Tak', value: true },
            { label: 'Nie', value: false },
          ],
        },
      },
    ],
    [t, lang]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      data?.orders?.map(
        ({
          id,
          product: { name, nameEn, seller },
          locker: { type, building },
          validFrom,
          validTo,
          user: { login, firstName, lastName, tenant },
          invoiceNeeded,
          buyer,
          payment: {
            createdAt,
            paymentId,
            status,
            logs,
            sandbox,
            sessionId,
            invoiceEmail,
            amount,
          },
        }) => ({
          id,
          paymentId,
          sessionId,
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          validFrom,
          validFromCSV: dateIso2ExcelFormat(validFrom),
          validTo,
          validToCSV: dateIso2ExcelFormat(validTo),
          productName: name,
          building: building.name,
          fv: invoiceNeeded,
          fvCSV: invoiceNeeded ? t('bool:yes') : t('bool:no'),
          login,
          fullName: `${firstName} ${lastName}`,
          nip: buyer?.nip,
          buyerName: buyer?.buyerName,
          zipcode: buyer?.zipcode,
          street: buyer?.street,
          city: buyer?.city,
          country: buyer?.country,
          tenantName: tenant?.tenantName,
          status,
          price: formatPrice(amount),
          priceCSV: formatToExcelPrice(amount),
          sandbox: sandbox ? 'sandbox' : undefined,
          logs,
          seller,
          invoiceEmail,
        })
      ) || []
    )
  }, [data, t])

  useEffect(() => {
    if (paymentId) {
      setOpenedDetails(tableData?.find(({ id }) => id === paymentId))
    }
  }, [paymentId, tableData])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('locker_payment_history')}
      </TypographyStyled>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        defaultColumnFilter={usr ? [{ id: 'login', value: usr }] : []}
        onRowClick={(rowData: Row) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getPaymentStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <LockerPaymentDetails
          closeDetails={() => setOpenedDetails(undefined)}
          data={openedDetails}
          refetch={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default PaymentHistory

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
