import gql from 'graphql-tag'
import { ILog } from '../shared/sharedTypes'

export interface IGetOneTimeParkingTicketLogsVars {
  id?: string
}

export interface IGetOneTimeParkingTicketLogsData {
  getOneTimeParkingTicket: {
    id: string
    logs: ILog[]
  }
}

export const GET_ONE_TIME_PARKING_TICKET_LOGS = gql`
  query getOneTimeParkingTicketLogs($id: ID!) {
    getOneTimeParkingTicket(id: $id) {
      id
      logs(order: { desc: created_at }) {
        createdAt: created_at
        message
        parameters {
          name
          value
          type
        }
      }
    }
  }
`
