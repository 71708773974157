import { ICompany } from './queryCompany'
import { LockerStatus } from '../lockers/queryLockers'

export type DietType = 'vegan' | 'vege' | 'none'

export enum Roles {
  admin = 'admin',
  developer = 'developer',
  tester = 'tester',
  restaurant = 'restaurant',
  parking = 'parking',
  parking_billing = 'parking_billing',
  parking_pool = 'parking_pool',
  events = 'events',
  events_tickets_scanner = 'events_tickets_scanner',
  survey = 'survey',
  survey_preview = 'survey_preview',
  locker = 'locker',
  locker_billing = 'locker_billing',
  locker_readonly = 'locker_readonly',
  getresponse = 'getresponse',
  push_messages = 'push_messages',
  payu = 'payu',
  hid = 'hid',
  access_privileges = 'access_privileges',
  banner = 'banner',
  banner_preview = 'banner_preview',
  app_store = 'app_store',
  office_manager = 'office_manager',
  quickpromo = 'quickpromo',
  issues = 'issues',
}

export enum ReservationStatus {
  available = 'available',
  prereserved = 'prereserved',
  reserved = 'reserved',
  rented = 'rented',
  error = 'error',
  cancelled = 'cancelled',
  finished = 'finished',
}

export interface IReservationUser {
  email: {
    email: string
  }
  firstName: string
  lastName: string
  login: string
  tenant?: {
    name: string
  }
}
export interface IReservation {
  id: string
  status: ReservationStatus
  createdAt: string
  validFrom: string
  validTo: string
  locker: {
    status: LockerStatus
  }
  user?: IReservationUser
  tenant?: {
    id: string
    name: string
    emails: {
      email: string
    }[]
  }
  logs: {
    createdAt: string
    message: string
  }[]
  order?: {
    orderId: string
    product: {
      name: string
      withShower?: boolean
      seller: ICompany
    }
  }
  groupReservation?: {
    tenantName: string
  }
}

export interface ILog {
  message: string
  createdAt: string
  parameters?: {
    name: string
    value: string
    type: string
  }[]
}

export enum PaymentStatus {
  new = 'new',
  received = 'received',
  processing = 'processing',
  confirmed = 'confirmed',
  completed = 'completed',
  failed = 'failed',
  error = 'error',
  cancelled = 'cancelled',
  timedout = 'timedout',
  partially_refunded = 'partially_refunded',
  fully_refunded = 'fully_refunded',
}

export interface IPayment {
  paymentId: string
  sessionId: string
  externalId: string
  createdAt: string
  status: PaymentStatus
  invoiceEmail: string
  sandbox: boolean
  amount: number
  seller: {
    name: string
    nip: string
    simplifiedInvoice: {
      enabled: boolean
    }
  }
  logs: ILog[]
}
