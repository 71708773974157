import { useMemo, FC, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  IGardenTicket,
  GardenTicketStatusType,
  IQueryGardenTicketData,
  QUERY_GARDEN_TICKETS,
  OTHER_GARDEN_TICKETS,
} from '../../graphql/garden/queryGardenTickets'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { themeColors } from '../../const/colors'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import { getGardenTicketStatusColor } from '../../utils/getStatusColor'
import { useParams } from 'react-router-dom'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

interface Row {
  id: string
  login?: string
  fullName: string
  ticketType: string
  email?: string
  company?: string
  qrCode: string
  orderId: string
  createdAt: string
  createdAtCSV: string
  validFrom: string
  validFromCSV: string
  status: GardenTicketStatusType
  statusTranslated: string
  orderStatus?: string
  price: number | string
  priceCSV: string
}

const GardenTicketsTable: FC<{
  tab: number
}> = ({ tab }) => {
  const { t, i18n } = useTranslation(['columns', 'generic'])
  const [items, setItems] = useState<IGardenTicket[]>([])
  const { usr } = useParams()
  const { loading: ticketsLoading } = useQuery<IQueryGardenTicketData>(
    !tab ? QUERY_GARDEN_TICKETS : OTHER_GARDEN_TICKETS,
    {
      onCompleted: (data: IQueryGardenTicketData) => {
        setItems(data.queryGardenTicket)
      },
      fetchPolicy: 'no-cache',
    }
  )

  const lang = i18n.language

  const getQRFromLink = (code: string) => {
    const qr = code.split('/')
    return qr[qr.length - 1]
  }

  const invisibleColumns = {
    priceCSV: false,
    validFromCSV: false,
    createdAtCSV: false,
  }

  const csvHeaderToDownload = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('company'),
      key: 'company',
    },
    {
      label: 'QRCode',
      key: 'qrCode',
    },
    {
      label: 'Order ID',
      key: 'orderId',
    },
    {
      label: t('created_at'),
      key: 'createdAtCSV',
    },
    {
      label: t('visit_date'),
      key: 'validFromCSV',
    },
    {
      label: t('status'),
      key: 'statusTranslated',
    },
    {
      label: `${t('price')} (PLN)`,
      key: 'priceCSV',
    },
  ]

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'fullName',
        header: t('full_name'),
      },
      { accessorKey: 'email', header: 'Email' },
      {
        accessorKey: 'login',
        header: 'Login',
      },
      {
        accessorKey: 'company',
        header: t('company'),
      },
      {
        accessorKey: 'qrCode',
        header: t('qr_code'),
      },
      {
        accessorKey: 'ticketType',
        header: t('ticket_type'),
      },
      {
        accessorKey: 'orderId',
        header: t('order_id'),
      },
      {
        accessorKey: 'createdAt',
        header: t('created_at'),

        cell: (value) => dateIso2localeString(value.getValue(), i18n.language),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'createdAtCSV',
        header: t('created_at'),
      },
      {
        accessorKey: 'validFrom',
        header: t('visit_date'),

        cell: (value) => dateIso2localeString(value.getValue(), i18n.language),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validFromCSV',
        header: t('visit_date'),
      },
      {
        accessorKey: 'statusTranslated',
        header: t('status'),
      },
      {
        accessorKey: 'orderStatus',
        header: t('payment_status'),
      },
      {
        accessorKey: 'price',
        header: t('price'),
      },
      {
        accessorKey: 'priceCSV',
        header: t('price'),
      },
    ],
    [t, i18n.language]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      items.map(
        ({
          id,
          name,
          nameEn,
          status,
          qrCode,
          createdAt,
          validFrom,
          user,
          price,
          order,
        }) => ({
          id,
          status,
          statusTranslated: t(`garden_ticket_status:${status}`),
          orderStatus: order
            ? t(`garden_order_status:${order.orderStatus}`)
            : 'Brak',
          ticketType: lang === 'pl' ? name : nameEn,
          qrCode: !price ? qrCode : getQRFromLink(qrCode),
          orderId: order?.orderId || 'Brak',
          fullName: `${user?.firstName || 'Brak'} ${user?.lastName || ''}`,
          company: user?.tenant?.name || 'Brak',
          login: user?.login || 'Brak',
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          validFrom,
          validFromCSV: dateIso2ExcelFormat(validFrom),
          email: user?.email?.email || 'Brak',
          workEmail: user?.workEmail?.email || 'Brak',
          price: price ? formatPrice(price) : `${t('generic:free')}`,
          priceCSV: price ? formatToExcelPrice(price) : `${t('generic:free')}`,
        })
      ) || []
    )
  }, [items, t, lang])

  if (ticketsLoading) {
    return <FullScreenLoader />
  }

  return (
    <Table
      columns={columns}
      data={tableData}
      columnVisibility={invisibleColumns}
      enableRowSelection
      defaultColumnFilter={usr ? [{ id: 'login', value: usr }] : []}
      csvExport
      csvHeaders={csvHeaderToDownload}
      getCellProps={(cellInfo) =>
        cellInfo.column.id === 'statusTranslated'
          ? {
              style: {
                color: getGardenTicketStatusColor(cellInfo.row.original.status),
                fontWeight: 600,
              },
            }
          : cellInfo.column.id === 'price' &&
            cellInfo.row.original.price === t('generic:free')
          ? {
              style: {
                color: themeColors.success,
                fontWeight: 600,
              },
            }
          : {}
      }
    />
  )
}

export default GardenTicketsTable
