import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import { useQuery, ApolloError } from '@apollo/client'
import {
  IQueryLockerGroupReservationData,
  QUERY_LOCKER_GROUP_RESERVATION,
} from '../../graphql/lockers/queryLockerGroupReservation'
import { ILog } from '../../graphql/shared/sharedTypes'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { Typography } from '@mui/material'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import { getReservationStatusColor } from '../../utils/getStatusColor'
import { LockerType } from '../../graphql/lockers/queryLockers'
import EditLockerGroupReservation from '../../components/LockerGroupReservations/EditLockerGroupReservation'
import ReservationTableActions from '../../components/LockerGroupReservations/ReservationTableActions'
import GroupReservationProtocolWrapper from '../../components/LockerGroupReservations/GroupReservationProtocolWrapper'
import { useAppSelector } from '../../redux/store'
import { checkRole } from '../../utils/checkRole'
import { LOCKER_ADMIN } from '../../const/permissions'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  id: string
  building: string
  status: ReservationStatus
  garageLockers: number
  roomLockers: number
  period: string
  tenant: string
  tenantId?: string
  tenantName: string
  tenantEmail: string
  price: number
  priceCSV: string
  createdAt: string
  validFrom: string
  validTo: string
  comment: string
  lockers: {
    name: string
    key: string
    transponder: string
    type: LockerType
  }[]
  logs: ILog[]
}

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

const GroupReservationList = () => {
  const { t, i18n } = useTranslation(['locker_group_reservation'])
  const lang = i18n.language
  const [openedDetails, setOpenedDetails] = useState<Row>()
  const [openedProtocol, setOpenedProtocol] = useState<Row>()
  const [pickUpProtocol, setPickUpProtocol] = useState(false)
  const [error, setError] = useState('')
  const { roles } = useAppSelector((state) => state.user)

  const handleCloseProtocol = () => {
    setPickUpProtocol(false)
    setOpenedProtocol(undefined)
  }

  const invisibleColumns = {
    id: false,
    priceCSV: false,
    validFrom: false,
    validTo: false,
    comment: false,
    actions: checkRole(roles, LOCKER_ADMIN),
  }

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: t('building'),
      key: 'building',
    },
    {
      label: t('valid_from'),
      key: 'validFrom',
    },
    {
      label: t('valid_to'),
      key: 'validTo',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('garage_lockers'),
      key: 'garageLockers',
    },
    {
      label: t('room_lockers'),
      key: 'roomLockers',
    },
    {
      label: `${t('price')} (PLN)`,
      key: 'priceCSV',
    },
  ]

  const { data, refetch, loading } = useQuery<IQueryLockerGroupReservationData>(
    QUERY_LOCKER_GROUP_RESERVATION,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'building',
        header: t('building'),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: (value) => t(`reservation_status:${value.getValue()}`),
      },
      {
        accessorKey: 'garageLockers',
        header: t('garage_lockers'),
      },
      {
        accessorKey: 'roomLockers',
        header: t('room_lockers'),
      },
      {
        accessorKey: 'period',
        header: t('period'),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tenant',
        header: t('tenant'),
      },
      {
        accessorKey: 'price',
        header: t('price'),
        cell: (value) => formatPrice(Number(value.getValue())),
      },
      {
        accessorKey: 'priceCSV',
        header: t('price'),
      },
      {
        accessorKey: 'createdAt',
        header: t('created_at'),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validFrom',
        header: t('validFrom'),
      },
      {
        accessorKey: 'validTo',
        header: t('validTo'),
      },
      {
        accessorKey: 'comment',
        header: t('comment'),
      },
      {
        accessorKey: 'actions',
        header: '',
        cell: (value) => (
          <ReservationTableActions
            data={value.row.original}
            setOpenedProtocol={setOpenedProtocol}
            setPickUpProtocol={setPickUpProtocol}
          />
        ),
        enableColumnFilter: false,
      },
    ],
    [t]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      data?.groupReservations.map(
        ({
          id,
          createdAt,
          validFrom,
          validTo,
          status,
          building,
          garageLockers,
          roomLockers,
          tenant,
          tenantName,
          tenantEmail,
          comment,
          price,
          logs,
          lockers,
        }) => ({
          id,
          createdAt: dateIso2localeString(createdAt, lang),
          validFrom: dateIso2ExcelFormat(validFrom),
          validTo: dateIso2ExcelFormat(validTo),
          period: `${dateIso2localeString(
            validFrom,
            lang,
            options
          )} - ${dateIso2localeString(validTo, lang, options)}`,
          status,
          building: building.name,
          garageLockers,
          roomLockers,
          tenant: tenant?.name ? tenant?.name : tenantName,
          tenantId: tenant?.id,
          tenantName,
          tenantEmail,
          comment,
          logs,
          price,
          priceCSV: formatToExcelPrice(price),
          lockers,
        })
      ) || []
    )
  }, [data, lang])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('group_reservations')}
      </TypographyStyled>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        onRowClick={(rowData: Row) => {
          checkRole(roles, LOCKER_ADMIN) && rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getReservationStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <EditLockerGroupReservation
          closeDetails={() => setOpenedDetails(undefined)}
          reservation={openedDetails}
          refetch={refetch}
        />
      </BaseDrawer>
      <BaseDrawer open={!!openedProtocol} variant={'temporary'}>
        <GroupReservationProtocolWrapper
          closeDrawer={handleCloseProtocol}
          reservation={openedProtocol}
          pickUp={pickUpProtocol}
        />
      </BaseDrawer>
    </>
  )
}

export default GroupReservationList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
