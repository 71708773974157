import { IReservation, ReservationStatus } from '../graphql/shared/sharedTypes'

const now = new Date(new Date().setHours(0, 0, 0, 0))

export const getCurrentReservation = (reservations: IReservation[]) =>
  reservations.find(
    ({ validFrom, validTo }) =>
      (new Date(new Date(validFrom).setHours(0, 0, 0, 0)) <= now &&
        new Date(new Date(validTo).setHours(0, 0, 0, 0)) >= now) ||
      new Date(new Date(validFrom).setHours(0, 0, 0, 0)) > now
  )

export const activeReservations = [
  ReservationStatus.rented,
  ReservationStatus.reserved,
]

export const getCurrentActiveReservation = (reservations: IReservation[]) =>
  reservations.find(
    ({ validFrom, validTo, status }) =>
      (new Date(new Date(validFrom).setHours(0, 0, 0, 0)) <= now &&
        new Date(new Date(validTo).setHours(0, 0, 0, 0)) >= now &&
        activeReservations.includes(status)) ||
      (new Date(new Date(validFrom).setHours(0, 0, 0, 0)) > now &&
        activeReservations.includes(status))
  )

export const getCurrentActiveReservations = (reservations: IReservation[]) =>
  reservations
    .filter(({ status }) => activeReservations.includes(status))
    .sort(
      (a, b) =>
        new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime()
    )
