import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DrawerHeader from '../Shared/DrawerHeader'
import DrawerButtons from '../Shared/DrawerButtons'
import { ITableRow } from '../../screens/Lockers/LockerProductsList'
import { TextBody2Medium, TextError } from '../Styles/TextCustom'
import styled from 'styled-components'
import FullScreenLoader from '../Shared/FullScreenLoader'
import LockerProductInputs from './LockerProductInputs'
import LogsContainer from '../Shared/LogsContainer'
import { toast } from 'react-toastify'
import {
  UPDATE_LOCKER_PRODUCT,
  IUpdateLockerProductVars,
} from '../../graphql/lockers/updateLockerProduct'
import { ApolloError, useMutation } from '@apollo/client'

const EditLockerProduct = ({
  closeDetails,
  lockerProduct,
  refetch,
}: {
  closeDetails: () => void
  lockerProduct?: ITableRow
  refetch: () => void
}) => {
  const { t } = useTranslation(['locker_products'])
  const [errorMessage, setErrorMessage] = useState('')
  const [disabled, setDisabled] = useState(lockerProduct?.disabled)
  const [withShower, setWithShower] = useState(!!lockerProduct?.withShower)
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()

  const onCompleted = () => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
    closeDetails()
  }

  const [updateLockerProduct, { loading }] =
    useMutation<IUpdateLockerProductVars>(UPDATE_LOCKER_PRODUCT, {
      onCompleted,
      onError: (error: ApolloError) => setErrorMessage(error.message),
    })

  if (!lockerProduct || loading) {
    return <FullScreenLoader />
  }
  const { logs, id } = lockerProduct

  const onSubmit = (data: any) => {
    const {
      name,
      nameEn,
      building,
      regulationId,
      durationDays,
      price,
      minAdvanceDays,
      maxAdvanceDays,
      periodName,
      periodNameEn,
      priceMessage,
      priceMessageEn,
    } = data
    updateLockerProduct({
      variables: {
        id,
        name,
        nameEn,
        building,
        regulationId,
        minAdvanceDays: parseInt(minAdvanceDays),
        maxAdvanceDays: parseInt(maxAdvanceDays),
        durationDays: parseInt(durationDays),
        price: parseInt(price) * 100,
        periodName,
        periodNameEn,
        priceMessage,
        priceMessageEn,
        disabled,
        withShower,
      },
    })
  }

  return (
    <>
      <DrawerHeader title={t('product_details')} handleClose={closeDetails} />
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <LockerProductInputs
          control={control}
          errors={errors}
          lockerProduct={lockerProduct}
          watch={watch}
          disabled={disabled}
          setDisabled={setDisabled}
          withShower={withShower}
          setWithShower={setWithShower}
        />
        <TextBody2Medium>{t('logs')}:</TextBody2Medium>
        <LogsContainer logs={logs} />
        <DrawerButtons
          handleAccept={handleSubmit(onSubmit)}
          handleCancel={closeDetails}
        />
        {(!!Object.keys(errors).length || errorMessage) && (
          <TextError>
            {!errorMessage ? t('form:form_error') : errorMessage}
          </TextError>
        )}
      </FormStyled>
    </>
  )
}

export default EditLockerProduct

const FormStyled = styled.form`
  padding: 16px 0 0;
`
