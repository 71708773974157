import { useTranslation } from 'react-i18next'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import styled from 'styled-components'
import { dateIso2localeString } from '../../utils/formatDate'
import { ITableRow } from '../LockerReservation/LockerReservationTable'
import { ITableRow as LockerRow } from '../Lockers/LockerTable'
import LockerNumbersContainer from '../LockerReservation/LockerNumbersContainer'

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

const ReservationSummaryDetails = ({
  reservations,
}: {
  reservations: ITableRow[] | LockerRow[]
}) => {
  const { t, i18n } = useTranslation(['lockers'])
  const lang = i18n.language
  const { building, user } = reservations[0]
  const oneReservation = reservations.length === 1 && reservations[0]

  return (
    <>
      <LockerNumbersContainer building={building.name} lockers={reservations} />
      {oneReservation && oneReservation.validFrom && oneReservation.validTo && (
        <>
          <TextWithPadding>{t('lease_term')}:</TextWithPadding>
          <TextBold>{`${dateIso2localeString(
            oneReservation.validFrom,
            lang,
            options
          )} - ${dateIso2localeString(
            oneReservation.validTo,
            lang,
            options
          )}`}</TextBold>
        </>
      )}
      {oneReservation && (
        <>
          <TextWithPadding>{t('tenant')}:</TextWithPadding>
          {oneReservation.tenant && (
            <TextBody2Bold>{oneReservation.tenant}</TextBody2Bold>
          )}
          {user && <TextBody2Bold>{user}</TextBody2Bold>}
          <TextBody2Bold>{oneReservation.email}</TextBody2Bold>
          {oneReservation.notes && (
            <>
              <TextWithPadding>{t('notes')}:</TextWithPadding>
              <TextBold>{oneReservation.notes}</TextBold>
            </>
          )}
        </>
      )}
    </>
  )
}

export default ReservationSummaryDetails

const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const TextWithPadding = styled(TextBody2Medium)`
  padding: 16px 0;
`
