import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { ApolloError, useQuery } from '@apollo/client'
import {
  QUERY_CAR_PLATE_VERIFICATION,
  IQueryCarPlateVerificationData,
} from '../../graphql/parking/queryCarPlateVerification'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { themeColors } from '../../const/colors'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  id: string
  createdAt: string
  createdAtCSV: string
  user: string
  carPlate: string
  found: boolean
}

const CarPlateVerificationReport = () => {
  const { t, i18n } = useTranslation(['parking'])
  const lang = i18n.language
  const [error, setError] = useState('')

  const invisibleColumns = {
    id: false,
    createdAtCSV: false,
  }

  const csvHeaders = [
    {
      label: 'Data',
      key: 'createdAtCSV',
    },
    {
      label: 'Kontroler',
      key: 'user',
    },
    {
      label: 'Tablica',
      key: 'carPlate',
    },
    {
      label: 'Bilet',
      key: 'found',
    },
  ]

  const { data, loading } = useQuery<IQueryCarPlateVerificationData>(
    QUERY_CAR_PLATE_VERIFICATION,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      { accessorKey: 'id', header: 'ID' },
      {
        accessorKey: 'createdAtCSV',
        header: t('payment_date_csv'),
      },
      {
        accessorKey: 'createdAt',
        header: 'Data',
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'user',
        header: t('controller'),
      },
      {
        accessorKey: 'carPlate',
        header: t('car_plate'),
      },
      {
        accessorKey: 'found',
        header: t('ticket'),
        cell: (value) => (value.getValue() ? t('bool:yes') : t('bool:no')),
      },
    ],
    [t, lang]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      data?.queryCarPlateVerification?.map(
        ({
          id,
          createdAt,
          carPlate,
          user: { firstName, lastName, login },
          found,
        }) => ({
          id,
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          carPlate,
          user: login,
          found,
        })
      ) || []
    )
  }, [data])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('admin_menu:car_plate_verification_report')}
      </TypographyStyled>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        getCellProps={({ column, row }) =>
          column.id === 'found' && !!row.original.found
            ? {
                style: {
                  color: themeColors.accept,
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default CarPlateVerificationReport

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
