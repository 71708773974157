import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import { InputAdornment } from '@mui/material'
import styled from 'styled-components'
import Input from '../Shared/Input'
import { TextBody1 } from '../Styles/TextCustom'
import { Row } from '../Parking/ParkingPassesTable'
import VehicleTypeSelect from './VehicleTypeSelect'

const ParkingPassInputs: FC<{
  control: any
  errors: IFormError | any
  parkingPass?: Row
}> = ({ control, errors, parkingPass }) => {
  const { t } = useTranslation(['parking_subscription_form', 'form'])

  return (
    <Container>
      <TextBody1>{t('subscription')}</TextBody1>
      <InputStyled
        data-e2e="parking-pass-input-name"
        label={t('subscription_pl')}
        name="name"
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        defaultValue={parkingPass?.namePl || ''}
      />
      <InputStyled
        data-e2e="parking-pass-input-name-en"
        label={t('subscription_en')}
        name="nameEn"
        control={control}
        errors={errors}
        defaultValue={parkingPass?.nameEn || ''}
        rules={{ required: `${t('form:field_required')}` }}
      />
      <TextBody1>{t('amano_product_no')}</TextBody1>
      <ShortInput
        data-e2e="parking-pass-input-amano-product-no"
        label={t('number')}
        name="amanoProductNo"
        control={control}
        errors={errors}
        type="number"
        defaultValue={parkingPass?.amanoProductNo || ''}
      />
      <VehicleTypeSelect
        control={control}
        errors={errors}
        parkingPass={parkingPass}
      />
      <TextBody1>{t('duration')}</TextBody1>
      <ShortInput
        data-e2e="parking-pass-input-duration"
        label={t('days')}
        type="number"
        name="durationDays"
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        defaultValue={parkingPass?.durationDays || 0}
      />
      <TextBody1>{t('price')}</TextBody1>
      <ShortInput
        data-e2e="parking-pass-input-price"
        label={t('price_label')}
        name="price"
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">zł</InputAdornment>,
        }}
        defaultValue={
          parkingPass?.rawPrice
            ? ((parkingPass.rawPrice as number) * 0.01).toFixed(2)
            : ''
        }
      />
      <TextBody1>{t('min_advance')}</TextBody1>
      <ShortInput
        data-e2e="parking-pass-input-min-advance-days"
        label={t('days')}
        name="minAdvanceDays"
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        type="number"
        defaultValue={parkingPass?.minAdvanceDays || 0}
      />
      <TextBody1>{t('max_advance')}</TextBody1>
      <ShortInput
        data-e2e="parking-pass-input-max-advance-days"
        label={t('days')}
        name="maxAdvanceDays"
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        type="number"
        defaultValue={parkingPass?.maxAdvanceDays || 0}
      />
      <TextBody1>{t('min_advance_others')}</TextBody1>
      <ShortInput
        data-e2e="parking-pass-input-min-advance-work-days"
        label={t('days')}
        name="minAdvanceWorkDays"
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        type="number"
        defaultValue={parkingPass?.minAdvanceWorkDays || 0}
      />
      <TextBody1>{t('after_end')}</TextBody1>
      <ShortInput
        data-e2e="parking-pass-input-overflow-days"
        label={t('days')}
        name="overflowReservationDays"
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        type="number"
        defaultValue={parkingPass?.overflowReservationDays || 0}
      />
      <TextBody1>{t('sort')}</TextBody1>
      <ShortInput
        data-e2e="parking-pass-input-sort"
        label={t('value')}
        name="sort"
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        type="number"
        defaultValue={parkingPass?.sort || 0}
      />
      <TextBody1>{`${t('currency')}:`}</TextBody1>
      <ShortInput
        data-e2e="parking-pass-input-amano-currency"
        label={t('currency')}
        name="amanoCurrency"
        control={control}
        errors={errors}
        defaultValue={parkingPass?.amanoCurrency || ''}
      />
    </Container>
  )
}

export default ParkingPassInputs

const InputStyled = styled(Input)`
  max-width: 600px;
  margin-bottom: 8px;
`
const ShortInput = styled(Input)`
  max-width: 170px;
  display: inline-block;
`
const Container = styled.div`
  max-width: 600px;
`
