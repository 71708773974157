import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DrawerHeader from '../Shared/DrawerHeader'
import DrawerButtons from '../Shared/DrawerButtons'
import { ITableRow } from '../LockerReservation/LockerReservationTable'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold, TextError } from '../Styles/TextCustom'
import styled from 'styled-components'
import FullScreenLoader from '../Shared/FullScreenLoader'
import RentLockerInputs from './RentLockerInputs'
import LogsContainer from '../Shared/LogsContainer'
import LockerReservationStatusSelect from '../LockerReservation/UncontrolledReservationStatusSelect'
import {
  GROUP_RESERVE_LOCKERS,
  IGroupReserveLockersVars,
  IGroupReserveLockersData,
} from '../../graphql/lockers/groupReserveLockers'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import { date2isoString, date2localeString } from '../../utils/formatDate'
import { LockerType } from '../../graphql/lockers/queryLockers'
import {
  UPDATE_LOCKER,
  IUpdateLockerVars,
} from '../../graphql/lockers/updateLocker'

type FormData = {
  tenant: string
  tenantEmail: string
  tenantName: string
  comment: string
  status: ReservationStatus
  validFrom: Date
  validTo?: Date
}

const RentLockerManually = ({
  closeDetails,
  locker,
  refetch,
}: {
  closeDetails: () => void
  locker?: ITableRow
  refetch: () => void
}) => {
  const { t, i18n } = useTranslation(['lockers'])
  const lang = i18n.language
  const [errorMessage, setErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)

  const now = date2localeString(new Date(), lang, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onCompleted = (data: IGroupReserveLockersData) => {
    const { status, error } = data.groupReserveLockers
    if (status) {
      successNotify(`${t('generic:reservation_created')}`)
      refetch()
      closeDetails()
    }
    if (error) {
      setErrorMessage(error)
    }
  }

  const [groupReserveLockers, { loading }] = useMutation<
    IGroupReserveLockersData,
    IGroupReserveLockersVars
  >(GROUP_RESERVE_LOCKERS, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })
  const [updateLocker] = useMutation<IUpdateLockerVars>(UPDATE_LOCKER, {
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  if (!locker || loading) {
    return <FullScreenLoader />
  }

  const { building, name, key, transponder, type, logs, id, notes } = locker

  const onSubmit = (data: FormData) => {
    let variables = {
      validFrom: date2isoString(data.validFrom),
      validTo: data.validTo ? date2isoString(data.validTo) : undefined,
      buildingName: building.name,
      requestedStatus: data.status,
      tenantId: data.tenant,
      tenantEmail: data.tenantEmail,
      tenantName: data.tenantName,
      comment: data.comment,
      suggestedIds: id,
      requestedGarageLockers: 0,
      requestedRoomLockers: 0,
    }

    if (type === LockerType.garage) {
      variables.requestedGarageLockers = 1
    } else {
      variables.requestedRoomLockers = 1
    }
    groupReserveLockers({
      variables,
    })
    if (data.comment) {
      updateLocker({
        variables: {
          id,
          notes: `${now}:${data.comment}${notes ? `, ${notes}` : ''}`,
        },
      })
    }
  }

  const details = [
    { label: t('building'), value: building.name },
    { label: t('locker_type'), value: t(type) },
    { label: t('locker_number'), value: name },
    { label: t('key_number'), value: key },
    { label: t('transponder_number'), value: transponder },
  ]

  return (
    <>
      <DrawerHeader
        title={t('rent_locker_manually')}
        handleClose={closeDetails}
      />
      {details.map(
        ({ label, value }, i) =>
          value && (
            <Row key={i}>
              <Text>{`${label}: `}</Text>
              <TextBold>{value}</TextBold>
            </Row>
          )
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <SelectWrapper>
          <LockerReservationStatusSelect control={control} errors={errors} />
        </SelectWrapper>
        <RentLockerInputs control={control} errors={errors} />
        <Text>{t('logs')}:</Text>
        <LogsContainer logs={logs} />
        <DrawerButtons
          handleAccept={handleSubmit(onSubmit)}
          handleCancel={closeDetails}
        />
        {(!!Object.keys(errors).length || errorMessage) && (
          <TextError>
            {!errorMessage ? t('form:form_error') : errorMessage}
          </TextError>
        )}
      </form>
    </>
  )
}

export default RentLockerManually
const Row = styled(Grid)`
  padding: 6px;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const SelectWrapper = styled(Grid)`
  padding: 16px 0 0;
`
