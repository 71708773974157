import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import BaseDrawer from '../Shared/BaseDrawer'
import FullScreenLoader from '../Shared/FullScreenLoader'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import UserMessageDetails from './UserMessageDetails'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import {
  QUERY_ISSUE,
  IQueryIssueVars,
  IQueryIssueData,
  newMessageIssuesFilter,
  resolvedMessageIssuesFilter,
  IssueType,
} from '../../graphql/userIssues/queryIssue'
import { useParams } from 'react-router-dom'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  id: string
  fullName: string
  login: string
  email: string
  message: string
  reply: string
  createdAt: string
  createdAt_csv: string
  closeAt: string
  closeAt_csv: string
  company?: string
  disabled: boolean
  os?: string
  app: string
  apiVersion: number
  backendVersion: string
  type: IssueType
  resolvedBy: {
    login: string
    firstName: string
    lastName: string
  }
}

const ResidentIssues: FC<{
  tab: number
  updateAggregatedCount: () => void
}> = ({ tab, updateAggregatedCount }) => {
  const { t, i18n } = useTranslation(['columns', 'admin_menu'])
  const [openedIssue, setOpenedIssue] = useState<Row>()
  const { usr } = useParams()
  const {
    data: issues,
    loading: loadingIssues,
    refetch,
  } = useQuery<IQueryIssueData, IQueryIssueVars>(QUERY_ISSUE, {
    variables: {
      filter: tab ? resolvedMessageIssuesFilter : newMessageIssuesFilter,
      sortKey: tab ? 'modified_at' : 'created_at',
    },
    fetchPolicy: 'no-cache',
  })

  const messageStyles = (text: string) => <MessageCell>{text}</MessageCell>

  const invisibleColumns = {
    reply: Boolean(tab),
    createdAtCSV: false,
    closeAtCSV: false,
    closeAt: Boolean(tab),
  }

  const csvHeadersTab0 = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('message'),
      key: 'message',
    },
    {
      label: t('created_at'),
      key: 'createdAtCSV',
    },
  ]

  const csvHeadersTab1 = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('message'),
      key: 'message',
    },
    {
      label: t('reply'),
      key: 'reply',
    },
    {
      label: t('created_at'),
      key: 'createdAtCSV',
    },
    {
      label: t('close_date'),
      key: 'closeAtCSV',
    },
  ]

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        header: t('full_name'),
        accessorKey: 'fullName',
      },
      {
        header: t('login'),
        accessorKey: 'login',
      },
      {
        header: t('main_email'),
        accessorKey: 'email',
      },
      {
        header: t('message'),
        accessorKey: 'message',
        cell: (value) => messageStyles(value.row.original.message),
      },
      {
        header: t('reply'),
        accessorKey: 'reply',
        cell: (value) => messageStyles(value.row.original.reply),
      },
      {
        header: t('created_at'),
        accessorKey: 'createdAt',
        cell: (value) => dateIso2localeString(value.getValue(), i18n.language),
        enableColumnFilter: false,
      },
      {
        header: 'createdAt_csv',
        accessorKey: 'createdAtCSV',
      },
      {
        header: t('close_date'),
        accessorKey: 'closeAt',
        cell: (value) => dateIso2localeString(value.getValue(), i18n.language),
        enableColumnFilter: false,
      },
      {
        header: 'closeAt_csv',
        accessorKey: 'closeAtCSV',
      },
    ],
    [t, i18n]
  )

  const tableData: Row[] = useMemo(
    () =>
      issues?.queryIssue.map(
        ({
          id,
          description,
          createdAt,
          modifiedAt,
          comment,
          formName,
          type,
          os,
          app,
          apiVersion,
          backendVersion,
          user: { firstName, login, lastName, email, tenant, disabled },
          resolvedBy,
        }) => ({
          id,
          fullName: `${firstName} ${lastName}`,
          login: login,
          email: email.email,
          message: description,
          reply: comment,
          createdAt,
          createdAt_csv: dateIso2ExcelFormat(createdAt),
          closeAt: modifiedAt,
          closeAt_csv: dateIso2ExcelFormat(modifiedAt),
          company: tenant?.name,
          resolvedBy,
          formName,
          type,
          os,
          app,
          apiVersion,
          backendVersion,
          disabled,
        })
      ) || [],
    [issues?.queryIssue]
  )

  if (loadingIssues) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Table
        columns={columns}
        data={tableData}
        onRowClick={(rowData) => setOpenedIssue(rowData)}
        csvExport
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvHeaders={tab ? csvHeadersTab1 : csvHeadersTab0}
        defaultColumnFilter={usr ? [{ id: 'login', value: usr }] : []}
      />
      <BaseDrawer open={!!openedIssue} variant={'temporary'}>
        {openedIssue && (
          <UserMessageDetails
            issue={openedIssue}
            closeIssue={() => setOpenedIssue(undefined)}
            updateList={refetch}
            updateAggregatedCount={updateAggregatedCount}
            tab={tab}
          />
        )}
      </BaseDrawer>
    </>
  )
}

export default ResidentIssues

const MessageCell = styled(Grid)`
  max-height: 60px;
  max-width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
`
