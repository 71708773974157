import gql from 'graphql-tag'
import { LockerType } from './queryLockers'
import { ILog } from '../shared/sharedTypes'

export interface ILockerProduct {
  id: string
  name: string
  nameEn: string
  periodName: string
  periodNameEn: string
  disabled: boolean
  regulation: {
    title: string
    id: string
  }
  building: {
    id: string
    name: string
  }
  type: LockerType
  withShower: boolean
  durationDays: number
  minAdvanceDays: number
  maxAdvanceDays: number
  price: number
  priceMessage: string
  priceMessageEn: string
  seller: {
    name: string
  }
  logs: ILog[]
}

export interface IQueryLockerProductData {
  lockerProducts: ILockerProduct[]
}

export const QUERY_LOCKER_PRODUCT = gql`
  query queryLockerProduct {
    lockerProducts: queryLockerProduct(order: { asc: name }) {
      id
      name
      nameEn: name_en
      periodName: period_name
      periodNameEn: period_name_en
      disabled
      regulation {
        title
        id
      }
      building {
        id
        name
      }
      type
      withShower: with_shower
      durationDays: duration_days
      minAdvanceDays: min_advance_days
      maxAdvanceDays: max_advance_days
      price
      priceMessage: price_message
      priceMessageEn: price_message_en
      seller {
        name
      }
      logs {
        createdAt: created_at
        message
      }
    }
  }
`
