import gql from 'graphql-tag'

export interface IQueryExtrasVars {
  visible: boolean
}

export interface IExtras {
  id: string
  visible: boolean
  companyName: string
  companyLogo: string
  title: string
  titleEn: string
  description: string
  descriptionEn: string
  instruction: string
  instructionEn: string
  location: string
  locationEn: string
  validFrom: string
  validTo: string
  sort: number
  eventId?: string
  hasDiscount: boolean
  discount?: string
}

export interface IQueryExtrasData {
  queryQuickPromo: IExtras[]
}

export const QUERY_EXTRAS = gql`
  query queryQuickPromo($visible: Boolean) {
    queryQuickPromo(filter: { visible: $visible }, order: { asc: sort }) {
      id
      visible
      companyName: company_name
      companyLogo: company_logo
      title
      titleEn: title_en
      description: description_pl
      descriptionEn: description
      instruction: instruction_pl
      instructionEn: instruction
      location: location_pl
      locationEn: location
      validFrom: valid_from
      validTo: valid_to
      sort
      eventId: event_id
      hasDiscount: has_discount
      discount: discount_info
    }
  }
`
