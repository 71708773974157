import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { themeColors } from '../../const/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

interface IBaseLinkProps {
  path: string
  prop: {
    [k: string]: string | boolean
  }
  text: string
}

const BaseLink = (props: IBaseLinkProps) => {
  const { path, prop, text } = props

  return (
    <LinkWrapper>
      <LinkStyled
        data-e2e="link-button"
        to={`/admin/${path}`}
        state={{ prop }}
        target="_blank"
      >
        {text}{' '}
        <FontAwesomeIcon
          icon={faExternalLinkAlt as IconProp}
          size="1x"
          color={themeColors.primary}
        />
      </LinkStyled>
    </LinkWrapper>
  )
}
export default BaseLink

const LinkStyled = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.25;
  font-weight: 600;
  color: ${themeColors.primary};
`
const LinkWrapper = styled.div`
  margin: 16px 0;
`
