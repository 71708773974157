import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextBody1Bold } from '../../components/Styles/TextCustom'
import { useQuery, ApolloError } from '@apollo/client'
import {
  IQueryLockersData,
  QUERY_LOCKERS,
  LockerType,
  ILocker,
} from '../../graphql/lockers/queryLockers'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import LockerReservationFilters from '../../components/LockerReservation/LockerReservationFilters'
import { themeColors } from '../../const/colors'
import LockerReservationTable from '../../components/LockerReservation/LockerReservationTable'
import RentManyLockersManually from '../../components/LockerRent/RentManyLockersManually'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import { HeaderContainer } from '../../components/Styles/CustomElementsStyled'
import { BaseButton } from '../../components/Shared/BaseButton'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { getCurrentReservation } from '../../utils/getCurrentReservation'
import { Row } from '../../components/LockerReservation/LockerReservationTable'
import { useAppSelector } from '../../redux/store'
import { checkRole } from '../../utils/checkRole'
import { LOCKER_ADMIN } from '../../const/permissions'
import ErrorContainer from '../../components/Shared/ErrorContainer'

const LockersReservationList = () => {
  const { t } = useTranslation(['lockers'])
  const [buildingFilter, setBuildingFilter] = useState('')
  const [lockerTypeFilter, setLockerTypeFilter] = useState<LockerType | ''>('')
  const [reservationStatus, setReservationStatus] = useState<
    ReservationStatus | ''
  >('')
  const [error, setError] = useState('')
  const [lockers, setLockers] = useState<ILocker[]>([])
  const [rentLockersManually, setRentLockersManually] = useState(false)
  const [selectedRows, setSelectedRows] = useState<Row[]>([])
  const [loading, setLoading] = useState(true)
  const { roles } = useAppSelector((state) => state.user)

  const { data, refetch } = useQuery<IQueryLockersData>(QUERY_LOCKERS, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => setError(error.message),
  })

  useEffect(() => {
    if (data) {
      let items: ILocker[] = data.lockers
      if (reservationStatus) {
        reservationStatus === ReservationStatus.available
          ? (items = data.lockers.filter(
              ({ reservations }) => !getCurrentReservation(reservations)
            ))
          : (items = data.lockers.filter(
              ({ reservations }) =>
                getCurrentReservation(reservations)?.status ===
                reservationStatus
            ))
      }
      if (buildingFilter) {
        items = items.filter(({ building }) => building.name === buildingFilter)
      }
      if (lockerTypeFilter) {
        items = items.filter(({ type }) => type === lockerTypeFilter)
      }
      setLockers(items)
      setLoading(false)
    }
  }, [reservationStatus, data, buildingFilter, lockerTypeFilter])

  if (loading || !data) {
    return <FullScreenLoader />
  }

  return (
    <>
      <HeaderContainer>
        <TypographyStyled variant="h6">
          {t('bike_locker_reservations')}
        </TypographyStyled>
        {checkRole(roles, LOCKER_ADMIN) && (
          <BaseButton
            onClick={() => setRentLockersManually(true)}
            data-e2e="rent-lockers-button"
          >
            {t('rent_locker_manually')}
          </BaseButton>
        )}
      </HeaderContainer>
      <LockerReservationTable
        lockers={lockers}
        refetch={refetch}
        building={buildingFilter}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      >
        <LockerReservationFilters
          building={buildingFilter}
          setBuilding={setBuildingFilter}
          lockerTypeFilter={lockerTypeFilter}
          setLockerTypeFilter={setLockerTypeFilter}
          reservationStatus={reservationStatus}
          setReservationStatus={setReservationStatus}
        />
      </LockerReservationTable>
      {!lockers.length && (
        <TextBody1Bold color={themeColors.primary}>
          {t('no_results')}
        </TextBody1Bold>
      )}
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!rentLockersManually} variant={'temporary'}>
        <RentManyLockersManually
          closeDrawer={() => setRentLockersManually(false)}
          lockers={data.lockers}
          selectedLockers={selectedRows}
          building={buildingFilter}
          refetch={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default LockersReservationList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
