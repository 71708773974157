import React, { FC, useState } from 'react'
import { useAppSelector } from '../../redux/store'
import { List, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { faCarAlt } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { checkRole } from '../../utils/checkRole'
import { PARKING_ROLES } from '../../const/permissions'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { MainListItem, ListItem, LinkStyled } from '../Styles/NavListStyle'

interface IParkingListItemProps {
  isMenuOpen?: boolean
}

const ParkingListItem: FC<IParkingListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])
  let { pathname } = useLocation()
  const [isParkingExpanded, setIsParkingExpanded] = useState(false)
  const { roles } = useAppSelector((state) => state.user)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : `/admin/${route}` === pathname

  if (!checkRole(roles, PARKING_ROLES)) {
    return null
  }

  return (
    <>
      <MainListItem
        onClick={() => setIsParkingExpanded(!isParkingExpanded)}
        data-e2e="navigation-parking"
      >
        <ListItemIcon>
          <FAIcon icon={faCarAlt as IconProp} />
        </ListItemIcon>
        <ListItemText primary={t('parking_areas')} />
        {isParkingExpanded ? <ExpandLess /> : <ExpandMore />}
      </MainListItem>
      <Collapse in={isMenuOpen && isParkingExpanded} timeout="auto">
        <List component="div">
          <LinkStyled to={ROUTES.PARKING_VALID_SUBSCRIPTIONS}>
            <ListItem
              selected={isSelected(
                ROUTES.PARKING_VALID_SUBSCRIPTIONS,
                isParkingExpanded
              )}
              data-e2e="navigation-parking-subscriptions-sold"
            >
              <ListItemText primary={t('parking_sold_subscription_list')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.PARKING_SOLD_ONE_TIME_TICKETS}>
            <ListItem
              selected={isSelected(
                ROUTES.PARKING_SOLD_ONE_TIME_TICKETS,
                isParkingExpanded
              )}
              data-e2e="navigation-parking-tickets-sold"
            >
              <ListItemText primary={t('parking_sold_one_time_tickets')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.PARKING_PAYMENT_HISTORY}>
            <ListItem
              selected={isSelected(
                ROUTES.PARKING_PAYMENT_HISTORY,
                isParkingExpanded
              )}
              data-e2e="navigation-parking-payment-history"
            >
              <ListItemText primary={t('payment_history')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.PARKING_SUBSCRIPTIONS_PRODUCTS_LIST}>
            <ListItem
              selected={isSelected(
                ROUTES.PARKING_SUBSCRIPTIONS_PRODUCTS_LIST,
                isParkingExpanded
              )}
              data-e2e="navigation-parking-products"
            >
              <ListItemText primary={t('parking_subscription_product_list')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.PARKING_LIST}>
            <ListItem
              selected={isSelected(ROUTES.PARKING_LIST, isParkingExpanded)}
              data-e2e="navigation-parking-list"
            >
              <ListItemText primary={t('parking_list')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.PARKING_PLACES}>
            <ListItem
              selected={isSelected(ROUTES.PARKING_PLACES, isParkingExpanded)}
              data-e2e="navigation-parking-places"
            >
              <ListItemText primary={t('parking_places')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.PARKING_TENANT_PLACES}>
            <ListItem
              selected={isSelected(
                ROUTES.PARKING_TENANT_PLACES,
                isParkingExpanded
              )}
              data-e2e="navigation-tenant-parking-places"
            >
              <ListItemText primary={t('tenant_parking_places')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.PARKING_RESERVATIONS}>
            <ListItem
              selected={isSelected(
                ROUTES.PARKING_RESERVATIONS,
                isParkingExpanded
              )}
            >
              <ListItemText primary={t('parking_place_reservations')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.PARKING_OCCUPANCY_REPORT}>
            <ListItem
              selected={isSelected(
                ROUTES.PARKING_OCCUPANCY_REPORT,
                isParkingExpanded
              )}
            >
              <ListItemText primary={t('parking_occupancy_report')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.TENANT_PARKING}>
            <ListItem
              selected={isSelected(ROUTES.TENANT_PARKING, isParkingExpanded)}
            >
              <ListItemText primary={t('tenant_parking')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.PARKING_POOL_OCCUPANCY_REPORT}>
            <ListItem
              selected={isSelected(
                ROUTES.PARKING_POOL_OCCUPANCY_REPORT,
                isParkingExpanded
              )}
            >
              <ListItemText primary={t('parking_pool_occupancy_report')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.PARKING_MANAGEMENT_REPORT}>
            <ListItem
              selected={isSelected(
                ROUTES.PARKING_MANAGEMENT_REPORT,
                isParkingExpanded
              )}
            >
              <ListItemText primary={t('parking_management_report')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.CAR_PLATE_VERIFICATION_REPORT}>
            <ListItem
              selected={isSelected(
                ROUTES.CAR_PLATE_VERIFICATION_REPORT,
                isParkingExpanded
              )}
            >
              <ListItemText primary={t('car_plate_verification_report')} />
            </ListItem>
          </LinkStyled>
        </List>
      </Collapse>
    </>
  )
}

export default ParkingListItem
