import { useMemo, useEffect, useState, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
  date2isoString,
} from '../../utils/formatDate'
import { Typography, Grid } from '@mui/material'
import styled from 'styled-components'
import { useLazyQuery, ApolloError } from '@apollo/client'
import {
  QUERY_PARKING_SUBSCRIPTIONS,
  IQuerySubscriptionVars,
  IQuerySubscriptionData,
  ParkingSubscriptionStatus,
  IParkingTicket,
} from '../../graphql/parking/queryParkingSubscriptions'
import { ILog } from '../../graphql/shared/sharedTypes'
import { useLocation } from 'react-router-dom'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { themeColors } from '../../const/colors'
import DatePicker from '../../components/Shared/DatePicker'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import SubscriptionDetails from '../../components/Parking/SubscriptionDetails'
import { getSubscriptionStatusColor } from '../../utils/getStatusColor'
import { formatToExcelPrice } from '../../utils/formatPrice'
import { useParams, useNavigate } from 'react-router-dom'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  id: string
  status: ParkingSubscriptionStatus
  validFrom: string
  validTo: string
  validFromCSV: string
  validToCSV: string
  createdAt: string
  createdAtCSV: string
  carPlates: string
  productName: string
  login: string
  email: string
  logs: ILog[]
  tickets: IParkingTicket[]
  tenant: string
  priceCSV?: string
  operator: string
}

const now = new Date(new Date().setHours(0, 0, 0, 0))

const SoldSubscriptionsList = () => {
  const { t, i18n } = useTranslation(['sold_parking_items'])
  const [minValidTo, setMinValidTo] = useState<Date | null>(now)
  const [openedDetails, setOpenedDetails] = useState<Row>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const location = useLocation()
  const columnFilter: any = location?.state
  const lang = i18n.language
  const navigate = useNavigate()
  const params = useParams() as { subscriptionId: string }

  const handleCloseDrawer = () => {
    setOpenedDetails(undefined)
    navigate('/admin/parking/sold-subscriptions', {
      state: {},
      replace: true,
    })
  }

  const invisibleColumns = {
    id: false,
    validFromCSV: false,
    validToCSV: false,
    tenant: false,
    createdAtCSV: false,
    priceCSV: false,
  }

  const csvHeaders = [
    {
      label: t('valid_from'),
      key: 'validFromCSV',
    },
    {
      label: t('valid_to'),
      key: 'validToCSV',
    },
    {
      label: t('product_name'),
      key: 'productName',
    },
    {
      label: t('car_plates'),
      key: 'carPlates',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Tenant',
      key: 'tenant',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('purchase_date'),
      key: 'createdAtCSV',
    },
    {
      label: `${t('payment_value')} (PLN)`,
      key: 'priceCSV',
    },
  ]

  const [querySubscriptions, { data, loading: queryLoading, refetch }] =
    useLazyQuery<IQuerySubscriptionData, IQuerySubscriptionVars>(
      QUERY_PARKING_SUBSCRIPTIONS,
      {
        fetchPolicy: 'no-cache',
        onError: (error: ApolloError) => setError(error.message),
        onCompleted: () => setLoading(false),
      }
    )

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'validFrom',
        header: t('valid_from'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validFromCSV',
        header: t('valid_from'),
      },
      {
        accessorKey: 'validTo',
        header: t('valid_to'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validToCSV',
        header: t('valid_to'),
      },
      {
        accessorKey: 'productName',
        header: t('product_name'),
        filterFn: 'equalsString',
      },
      {
        accessorKey: 'carPlates',
        header: t('car_plates'),
      },
      {
        accessorKey: 'login',
        header: 'Login',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'tenant',
        header: t('tenant'),
      },
      {
        accessorKey: 'status',
        header: t('status'),
        cell: (value) => t(`parking_subscription_status:${value.getValue()}`),
      },
      {
        accessorKey: 'createdAt',
        header: t('purchase_date'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'createdAtCSV',
        header: t('purchase_date'),
      },
      {
        accessorKey: 'priceCSV',
        header: t('payment_value'),
      },
    ],
    [t, lang]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      (!!data?.subscriptions[0] &&
        data?.subscriptions.map(
          ({
            id,
            status,
            validFrom,
            validTo,
            createdAt,
            carPlate,
            carPlate2,
            parkingPass: { name, nameEn, parking },
            user: { login, email, tenant },
            logs,
            tickets,
            activeParkingTicket,
            pendingParkingTicket,
          }) => ({
            id,
            validFrom,
            validTo,
            validFromCSV: dateIso2ExcelFormat(validFrom),
            validToCSV: dateIso2ExcelFormat(validTo),
            carPlates: `${tickets[0]?.carPlate}${
              tickets[0].carPlate2 ? `, ${tickets[0].carPlate2}` : ''
            }`,
            productName: lang === 'pl' ? name : nameEn,
            login,
            email: email?.email,
            status,
            createdAt,
            createdAtCSV: dateIso2ExcelFormat(createdAt),
            logs,
            tickets,
            tenant: tenant?.name,
            priceCSV: activeParkingTicket
              ? formatToExcelPrice(activeParkingTicket?.payment?.amount)
              : pendingParkingTicket
              ? formatToExcelPrice(pendingParkingTicket?.payment?.amount)
              : '',
            operator: parking.operator,
          })
        )) ||
      []
    )
  }, [data, lang])

  useLayoutEffect(() => {
    if (params.subscriptionId !== ':subscriptionId') {
      setOpenedDetails(tableData.find(({ id }) => id === params.subscriptionId))
    }
  }, [params, tableData])

  useEffect(() => {
    const timer = setTimeout(() => {
      minValidTo &&
        querySubscriptions({
          variables: { minValidTo: date2isoString(minValidTo) },
        })
    }, 1500)
    return () => clearTimeout(timer)
  }, [minValidTo])

  if (loading || queryLoading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Title variant="h6">{t('valid_subscriptions_list')}</Title>
      <RowContainer>
        <Subtitle>{t('valid_to_info')}</Subtitle>
        <DatePickerWrapper>
          <DatePicker
            selectedDate={minValidTo}
            setSelectedDate={setMinValidTo}
            label={t('valid_to')}
          />
        </DatePickerWrapper>
      </RowContainer>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        onRowClick={(rowData: Row) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getSubscriptionStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <SubscriptionDetails
          closeDetails={handleCloseDrawer}
          data={openedDetails}
          refetch={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default SoldSubscriptionsList

const Title = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background-color: ${themeColors.lightBlue};
  border-radius: 10px;
  margin: 8px 0 16px;
`
const Subtitle = styled(Typography)`
  font-weight: 500;
`
const DatePickerWrapper = styled(Grid)`
  padding: 0 16px;
`
