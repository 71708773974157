import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TextBody2Medium } from '../../components/Styles/TextCustom'
import {
  useQuery,
  ApolloError,
  useMutation,
  useSubscription,
  OnSubscriptionDataOptions,
} from '@apollo/client'
import {
  IQueryGetResponseJobData,
  QUERY_GETRESPONSE_JOB,
} from '../../graphql/getResponseSync/querySyncJobs'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { Typography, LinearProgress, Grid } from '@mui/material'
import { HeaderContainer } from '../../components/Styles/CustomElementsStyled'
import { BaseButton } from '../../components/Shared/BaseButton'
import {
  IGetResponseSyncData,
  GETRESPONSE_SYNC,
  GetResponseJobStatus,
} from '../../graphql/getResponseSync/getResponseSync'
import {
  ICancelGetResponseSyncVars,
  ICancelGetResponseSyncData,
  CANCEL_GETRESPONSE_SYNC,
} from '../../graphql/getResponseSync/cancelGetResponseSync'
import {
  IGetResponseJobSubscriptionVars,
  IGetResponseJobSubscriptionData,
  GETRESPONSE_JOB_SUBSCRIPTION,
} from '../../graphql/getResponseSync/subscriptionGetResponseJob'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { getSyncJobStatusColor } from '../../utils/getStatusColor'
import { Button } from '@mui/material'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  id: string
  date: string
  dateCSV: string
  recordsProcessed: number
  recordsCreated: number
  recordsDeleted: number
  recordsUpdated: number
  recordsError: number
  status: GetResponseJobStatus
}

const SynchronizationScreen = () => {
  const { t, i18n } = useTranslation(['getresponse'])
  const lang = i18n.language
  const [error, setError] = useState('')
  const [syncId, setSyncId] = useState('')
  const completedStatuses = [
    GetResponseJobStatus.completed,
    GetResponseJobStatus.canceled,
    GetResponseJobStatus.error,
  ]

  const invisibleColumns = {
    id: false,
    dateCSV: false,
  }

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: t('sync_date'),
      key: 'dateCSV',
    },
    {
      label: t('processed_records'),
      key: 'recordsProcessed',
    },
    {
      label: t('added_records'),
      key: 'recordsCreated',
    },
    {
      label: t('deleted_records'),
      key: 'recordsDeleted',
    },
    {
      label: t('modified_records'),
      key: 'recordsUpdated',
    },
    {
      label: t('errors'),
      key: 'recordsError',
    },
    {
      label: 'Status',
      key: 'status',
    },
  ]

  const onCompleted = (data: IGetResponseSyncData) => {
    if (data.sync) {
      setSyncId(data.sync.job.id)
    }
    refetch()
  }
  const onCancelCompleted = (data: ICancelGetResponseSyncData) => {
    if (data?.cancelSync.status) {
      setSyncId('')
      refetch()
    }
  }

  const { data, refetch, loading } = useQuery<IQueryGetResponseJobData>(
    QUERY_GETRESPONSE_JOB,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  useSubscription<
    IGetResponseJobSubscriptionData,
    IGetResponseJobSubscriptionVars
  >(GETRESPONSE_JOB_SUBSCRIPTION, {
    variables: {
      id: syncId,
    },
    onSubscriptionData: (
      data: OnSubscriptionDataOptions<IGetResponseJobSubscriptionData>
    ) => {
      if (data) {
        const status = data.subscriptionData.data?.getGetResponseJob.status
        if (status) {
          completedStatuses.includes(status) && setSyncId('')
          refetch()
        }
      }
    },
  })

  const [getResponseSync] = useMutation<IGetResponseSyncData>(
    GETRESPONSE_SYNC,
    {
      fetchPolicy: 'no-cache',
      onCompleted,
    }
  )

  const [cancelGetResponseSync] = useMutation<
    ICancelGetResponseSyncData,
    ICancelGetResponseSyncVars
  >(CANCEL_GETRESPONSE_SYNC, {
    variables: {
      id: syncId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: onCancelCompleted,
  })

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
      },
      {
        header: t('sync_date'),
        accessorKey: 'date',
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        header: t('sync_date'),
        accessorKey: 'dateCSV',
      },
      {
        header: t('processed_records'),
        accessorKey: 'recordsProcessed',
      },
      {
        header: t('added_records'),
        accessorKey: 'recordsCreated',
      },
      {
        header: t('deleted_records'),
        accessorKey: 'recordsDeleted',
      },
      {
        header: t('modified_records'),
        accessorKey: 'recordsUpdated',
      },
      {
        header: t('errors'),
        accessorKey: 'recordsError',
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (value) => t(`${value.getValue()}`),
      },
    ],
    [t, lang]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      data?.queryGetResponseJob.map(
        ({
          id,
          createdAt,
          startedAt,
          recordsProcessed,
          recordsCreated,
          recordsUpdated,
          recordsDeleted,
          recordsError,
          status,
        }) => ({
          id,
          date: createdAt,
          dateCSV: dateIso2ExcelFormat(createdAt),
          recordsProcessed,
          recordsCreated,
          recordsUpdated,
          recordsDeleted,
          recordsError,
          status,
        })
      ) || []
    )
  }, [data])

  useEffect(() => {
    if (data?.queryGetResponseJob) {
      const pendingSync = data.queryGetResponseJob.find(
        ({ status }) => !completedStatuses.includes(status)
      )
      pendingSync && setSyncId(pendingSync.id)
    }
  }, [data])

  const handleSync = () => getResponseSync()

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Header>
        <TypographyStyled variant="h6">{t('title')}</TypographyStyled>
        <BaseButton onClick={handleSync}>{t('sync')}</BaseButton>
      </Header>

      {!!syncId && (
        <Header>
          <ProgressWrapper>
            <Text>{t('sync_progress')}</Text>
            <LinearProgress />
          </ProgressWrapper>
          <ButtonStyled
            color={'error'}
            variant="outlined"
            size="medium"
            onClick={() => cancelGetResponseSync()}
          >
            {t('cancel_sync')}
          </ButtonStyled>
        </Header>
      )}

      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getSyncJobStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default SynchronizationScreen
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
const ProgressWrapper = styled(Grid)`
  padding: 20px 10px 20px 0;
  width: 100%;
`
const Header = styled(HeaderContainer)`
  margin-bottom: 20px;
`
const Text = styled(TextBody2Medium)`
  padding-bottom: 16px;
`
const ButtonStyled = styled(Button)`
  border-radius: 100px;
`
