import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import { Container, Badge, Tab, Tabs, Grid } from '@mui/material'

export const LightBlueContainer = styled(Container)`
  background-color: ${themeColors.lightBlue};
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CenterContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`

export const HeaderContainer = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Img = styled.img`
  margin-bottom: 40px;
  max-width: 300px;
  @media (min-width: 700px) {
    max-width: 515px;
  }
`
export const BadgeStyled = styled(Badge)`
  & .MuiBadge-badge {
    top: 32%;
    right: -10%;
  }
`
export const TabsStyled = styled(Tabs)`
  margin-bottom: 24px;
`
export const TabStyled = styled(Tab)`
  font-weight: 600;
`

export const ModalContent = styled(Grid)`
  font-size: 18px;
  padding: 30px;
  font-weight: 500;
  max-width: 350px;
`
export const DetailsWrapper = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
