import { Roles } from '../graphql/shared/sharedTypes'

export const SIGN_IN_ROLES = [
  Roles.admin,
  Roles.restaurant,
  Roles.parking,
  Roles.parking_billing,
  Roles.survey,
  Roles.locker,
  Roles.locker_readonly,
  Roles.getresponse,
  Roles.push_messages,
  Roles.payu,
  Roles.access_privileges,
  Roles.banner,
  Roles.quickpromo,
  Roles.issues,
]
export const ADMIN = [Roles.admin]

export const USER_ROLES = [Roles.admin, Roles.issues]

export const RESTAURANT_ROLES = [Roles.admin, Roles.restaurant]

export const PARKING_ROLES = [Roles.admin, Roles.parking, Roles.parking_billing]

export const PARKING_BILLING = [Roles.parking_billing]

export const SURVEY_ROLES = [Roles.admin, Roles.survey]

export const LOCKER_ROLES = [Roles.admin, Roles.locker, Roles.locker_readonly]

export const LOCKER_ADMIN = [Roles.admin, Roles.locker]

export const GETRESPONSE_ROLES = [Roles.admin, Roles.getresponse]

export const PUSH_ROLES = [Roles.admin, Roles.push_messages]

export const PAYU_ROLES = [Roles.payu]

export const ACCESS_PRIVILEGES_ROLES = [Roles.access_privileges]

export const BANNER = [Roles.admin, Roles.banner]

export const EXTRAS_ROLES = [Roles.admin, Roles.quickpromo]

export const ISSUE_ROLES = [Roles.admin, Roles.issues]
