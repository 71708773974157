import { useTranslation } from 'react-i18next'
import { ITableRow } from '../../screens/Lockers/GroupReservationList'
import DrawerHeader from '../Shared/DrawerHeader'
import GroupReservationSummary from '../LockerProtocols/GroupReservationSummary'

const GroupReservationProtocolWrapper = ({
  closeDrawer,
  reservation,
  pickUp = false,
}: {
  closeDrawer: () => void
  reservation?: ITableRow
  pickUp?: boolean
}) => {
  const { t } = useTranslation(['lockers'])

  return (
    <>
      <DrawerHeader
        title={pickUp ? t('pickup_protocol') : t('transfer_protocol')}
        handleClose={closeDrawer}
      />
      {reservation && (
        <GroupReservationSummary
          reservationId={reservation.id}
          pickUp={pickUp}
        />
      )}
    </>
  )
}

export default GroupReservationProtocolWrapper
