import styled from 'styled-components'
import { VehicleType } from '../../graphql/parking/getParkingPasses'
import { TextBody1 } from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import BasicSelect from '../Shared/BasicSearchableSelect'
import { IFormError } from '../../types/FormTypes'
import { ITableRow } from './ParkingPassesTable'

const VehicleTypeSelect = ({
  control,
  errors,
  parkingPass,
}: {
  control: any
  errors: IFormError | any
  parkingPass?: ITableRow
}) => {
  const { t } = useTranslation(['parking_subscription_form', 'form'])
  const data = [VehicleType.car, VehicleType.motorcycle, VehicleType.bike]

  return (
    <>
      <TextBody1>{t('vehicle_type')}</TextBody1>
      <div data-e2e={'parking-pass-vehicle-type'}>
        <SelectStyled
          options={data.map((val) => ({
            value: val,
            label: t(val.toLocaleLowerCase()),
          }))}
          control={control}
          errors={errors}
          label={t('select_vehicle_type')}
          name={'vehicleType'}
          rules={{ required: `${t('form:field_required')}` }}
          defaultValue={parkingPass?.vehicleType}
        />
      </div>
    </>
  )
}

export default VehicleTypeSelect

const SelectStyled = styled(BasicSelect)`
  width: 174px;
`
