import { useState } from 'react'
import styled from 'styled-components'
import { Grid, ClickAwayListener } from '@mui/material'
import { TextBody1 } from '../Styles/TextCustom'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import {
  QUERY_COMPANY,
  IQueryCompanyData,
} from '../../graphql/parking/queryCompany'
import {
  UPDATE_PARKING,
  IUpdateParkingData,
  IUpdateParkingVars,
} from '../../graphql/parking/updateParking'
import Input from '../Shared/Input'
import BasicSelect from '../Shared/BasicSearchableSelect'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { Row } from './ParkingListTable'
import DrawerButtons from '../Shared/DrawerButtons'
import { themeColors } from '../../const/colors'
import DrawerHeader from '../Shared/DrawerHeader'
import { ROUTES } from '../../const/routes'
import BaseLink from '../Shared/BaseLink'

const EditParking = ({
  parking,
  closeDetails,
  updateList,
}: {
  parking?: Row
  closeDetails: () => void
  updateList: () => void
}) => {
  const { t } = useTranslation(['form', 'btn'])
  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const watchFormData = useWatch({ control })

  const onSubmit = (formData: any) => {
    const { parkingCapacity, sellerId, remarks } = formData
    parking &&
      updateParking({
        variables: {
          id: parking.id,
          capacity: parseInt(parkingCapacity),
          sellerId,
          remarks,
        },
      })
  }

  const updateAndClose = () => {
    successNotify(`${t('generic:success_message')}`)
    updateList()
    closeDetails()
  }
  const { data: company, loading } = useQuery<IQueryCompanyData>(QUERY_COMPANY)

  const [updateParking] = useMutation<IUpdateParkingData, IUpdateParkingVars>(
    UPDATE_PARKING,
    {
      onCompleted: updateAndClose,
      fetchPolicy: 'no-cache',
    }
  )

  const handleClickAway = () => {
    !!Object.keys(watchFormData).length
      ? setOpenClickAwayModal(true)
      : closeDetails()
  }

  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
  }

  if (!parking || loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader
            title={t('parking_list:edition_parking')}
            handleClose={handleClickAway}
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextBody1>{t('parking_list:name')}</TextBody1>
            <InputStyled
              label={'Parking'}
              name={'name'}
              control={control}
              errors={errors}
              rules={{ required: `${t('form:field_required')}` }}
              defaultValue={parking?.name || ''}
              disabled
            />
            <TextBody1>{t('parking_list:limit_place')}</TextBody1>
            <ShortInput
              label={t('parking_list:places')}
              name={'parkingCapacity'}
              control={control}
              errors={errors}
              type={'number'}
              defaultValue={parking?.capacity || 0}
              data-e2e="parking-list-input-places"
              disabled
            />
            <BaseLink
              path={ROUTES.PARKING_PLACES}
              prop={{ parkingProp: parking.name }}
              text={t('parking_list:go_to_places')}
            />

            {!!parking?.buildings && (
              <>
                <TextBody1>{t('parking_list:building')}</TextBody1>
                <GridStyled>
                  <TextBody1>{parking.buildings}</TextBody1>
                </GridStyled>
              </>
            )}

            <CategorySelectorContainer>
              <TextBody1>{t('parking_subscription_form:company')}:</TextBody1>
              {company?.queryCompany && (
                <SelectContainer data-e2e="parking-list-input-seller">
                  <BasicSelect
                    options={company.queryCompany.map(({ name, id }) => ({
                      value: id,
                      label: name,
                    }))}
                    control={control}
                    errors={errors}
                    label={t('parking_subscription_form:select_company')}
                    name={'sellerId'}
                    rules={{ required: `${t('form:field_required')}` }}
                    defaultValue={parking?.sellerId}
                  />
                </SelectContainer>
              )}
            </CategorySelectorContainer>

            <TextBody1>{t('parking_list:remarks')}</TextBody1>
            <InputStyled
              label={t('parking_list:remarks')}
              name={'remarks'}
              control={control}
              errors={errors}
              defaultValue={parking?.remarks || ''}
            />
            <Buttons
              handleAccept={handleSubmit(onSubmit)}
              handleCancel={handleClickAway}
            />
          </form>
        </>
      </ClickAwayListener>
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeDetails()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default EditParking

const InputStyled = styled(Input)`
  max-width: 600px;
`
const ShortInput = styled(Input)`
  max-width: 170px;
  display: inline-block;
`
const CategorySelectorContainer = styled.div`
  width: 600px;
`
const Buttons = styled(DrawerButtons)`
  margin: 16px;
`
const GridStyled = styled(Grid)`
  background-color: ${themeColors.background};
  padding: 1px 16px;
  max-width: 600px;
  border-radius: 15px;
`
const SelectContainer = styled.div`
  width: 600px;
`
