import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IqueryEmailVerificationRequestData {
  queryEmailVerificationRequest: {
    email: string
    user: { login: string; firstName: string; lastName: string }
    createdAt: string
    type: string
    service: string
  }[]
}

export const EMAIL_SENT_NOT_VERIFIED = gql`
  query EmailsSentNotVerified {
    queryEmailVerificationRequest(order: { desc: created_at }) {
      email
      user {
        login
        firstName: first_name
        lastName: last_name
      }
      createdAt: created_at
      type
      service
    }
  }
`
export interface IresendVerificationCodeVars {
  email: string
}
export interface IresendVerificationCodeData {
  resendVerificationCode: IQueryBasicData
}

export const RESEND_VERIFICATION_CODE = gql`
  mutation ResendVerificationCode($email: String!) {
    resendVerificationCode(email: $email) {
      status
      code
      message
      error
    }
  }
`
export interface IdeleteEmailVefiricationRequestVars {
  email: string
}

export const DELETE_VERIFICATION_CODE = gql`
  mutation deleteEmailVerificationRequest($email: String!) {
    deleteEmailVerificationRequest(filter: { email: { eq: $email } }) {
      msg
      numUids
      emailVerificationRequest {
        email
      }
    }
  }
`
export interface IdeleteEmailVefiricationRequestsVars {
  emails: string[]
}

export const DELETE_VERIFICATION_CODES = gql`
  mutation deleteEmailVerificationRequests($emails: [String]!) {
    deleteEmailVerificationRequest(filter: { email: { in: $emails } }) {
      msg
      numUids
      emailVerificationRequest {
        email
      }
    }
  }
`
