import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
  onlyDateIso2ExcelFormat,
  timeIso2ExcelFormat,
} from '../../utils/formatDate'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { ApolloError, useQuery } from '@apollo/client'
import {
  QUERY_PARKING_PAYMENT,
  IQueryParkingPaymentData,
  ParkingProduct,
} from '../../graphql/parking/queryParkingPayment'
import { useLocation } from 'react-router-dom'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { getPaymentStatusColor } from '../../utils/getStatusColor'
import { PaymentStatus } from '../../graphql/shared/sharedTypes'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import PaymentDetails from '../../components/Parking/PaymentDetails'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import { useParams } from 'react-router-dom'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  id: string
  paymentId?: string
  sessionId?: string
  paymentDate?: string
  paymentTime?: string
  paymentDay?: string
  createdAt?: string
  createdAtCSV: string
  validFrom: string
  validTo: string
  validFromCSV: string
  validToCSV?: string
  productName: string
  parkingName?: string
  simplifiedInvoice: boolean
  fv: boolean
  fvCSV: string
  invoiceEmail?: string
  login: string
  fullName: string
  nip?: string
  buyerName?: string
  zipcode?: string
  street?: string
  city?: string
  country?: string
  carPlate: string
  tenantName?: string
  status?: PaymentStatus
  price?: string
  priceCSV?: string
  logs?: {
    message: string
    createdAt: string
  }[]
  sandbox?: string
  seller?: {
    name: string
    nip: string
  }
  duration?: string
}
interface stateType {
  prop?: {
    paymentId: string
  }
}

const PaymentHistory = () => {
  const { t, i18n } = useTranslation(['payment_history'])
  const location = useLocation()
  const state: stateType = location.state as stateType
  const paymentId = state?.prop?.paymentId
  const lang = i18n.language
  const [openedDetails, setOpenedDetails] = useState<Row>()
  const [tickets, setTickets] = useState<ParkingProduct[]>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const { usr } = useParams()

  const formatDuration = (v: string) => v.replace('h', ':').split('m')[0]

  const invisibleColumns = {
    paymentDate: false,
    paymentTime: false,
    paymentDay: false,
    createdAtCSV: false,
    validFromDay: false,
    validFromCSV: false,
    validToCSV: false,
    fvCSV: false,
    nip: false,
    buyerName: false,
    zipcode: false,
    street: false,
    city: false,
    country: false,
    tenantName: false,
    priceCSV: false,
    duration: false,
  }

  const csvHeaders = [
    {
      label: t('payment_id'),
      key: 'id',
    },
    {
      label: t('payment_date'),
      key: 'createdAtCSV',
    },
    {
      label: 'Data płatności',
      key: 'paymentDate',
    },
    {
      label: 'Godzina płatności',
      key: 'paymentTime',
    },
    {
      label: 'Dzień płatności',
      key: 'paymentDay',
    },
    {
      label: t('ticket_start'),
      key: 'validFromCSV',
    },
    {
      label: 'Dzień rozpoczęcia biletu',
      key: 'validFromDay',
    },
    {
      label: t('ticket_stop'),
      key: 'validToCSV',
    },
    {
      label: t('product_name'),
      key: 'productName',
    },
    {
      label: 'Parking',
      key: 'parkingName',
    },
    {
      label: 'FV',
      key: 'fvCSV',
    },
    {
      label: 'NIP',
      key: 'nip',
    },
    {
      label: t('columns:full_name'),
      key: 'fullName',
    },
    {
      label: t('zipcode'),
      key: 'zipcode',
    },
    {
      label: t('street'),
      key: 'street',
    },
    {
      label: t('city'),
      key: 'city',
    },
    {
      label: t('country'),
      key: 'country',
    },
    {
      label: t('car_plate_1'),
      key: 'carPlate',
    },
    {
      label: t('tenant_name'),
      key: 'tenantName',
    },
    {
      label: `${t('payment_value_gross')} (PLN)`,
      key: 'priceCSV',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: 'Czas parkowania',
      key: 'duration',
    },
  ]

  const { data, refetch } = useQuery<IQueryParkingPaymentData>(
    QUERY_PARKING_PAYMENT,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => {
        setError(error.message)
        setLoading(false)
      },
    }
  )

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: t('payment_id'),
      },
      {
        accessorKey: 'createdAt',
        header: t('payment_date'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'paymentDate',
        header: t('payment_date'),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'paymentTime',
        header: 'Godzina płatności',
      },
      {
        accessorKey: 'paymentDay',
        header: 'Dzień tygodnia płatności',
      },
      {
        accessorKey: 'createdAtCSV',
        header: t('payment_date_csv'),
      },
      {
        accessorKey: 'validFrom',
        header: t('ticket_start'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validTo',
        header: t('ticket_stop'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validFromDay',
        header: 'Dzień tygodnia',
      },
      {
        accessorKey: 'validFromCSV',
        header: t('ticket_start'),
      },
      {
        accessorKey: 'validToCSV',
        header: t('ticket_stop'),
      },
      {
        accessorKey: 'productName',
        header: t('product_name'),
      },
      {
        accessorKey: 'status',
        header: t('payment_status'),
        cell: (value) => t(`payment_status:${value.getValue()}`),
      },
      {
        accessorKey: 'parkingName',
        header: t('parking_name'),
      },
      {
        accessorKey: 'fvCSV',
        header: t('fv'),
      },
      {
        accessorKey: 'fullName',
        header: t('columns:full_name'),
      },
      {
        accessorKey: 'login',
        header: t('columns:login'),
      },
      {
        accessorKey: 'nip',
        header: t('nip'),
      },
      {
        accessorKey: 'buyerName',
        header: t('name'),
      },
      {
        accessorKey: 'zipcode',
        header: t('zipcode'),
      },
      {
        accessorKey: 'street',
        header: t('street'),
      },
      {
        accessorKey: 'city',
        header: t('city'),
      },
      {
        accessorKey: 'country',
        header: t('country'),
      },
      {
        accessorKey: 'carPlate',
        header: t('car_plate_1'),
      },
      {
        accessorKey: 'tenantName',
        header: t('tenant_name'),
      },
      {
        accessorKey: 'price',
        header: t('payment_value_gross'),
      },
      {
        accessorKey: 'priceCSV',
        header: t('payment_value_gross'),
      },
      {
        accessorKey: 'sandbox',
        header: 'Sandbox',
        meta: {
          filterVariant: 'select',
          options: [
            { label: 'Tak', value: true },
            { label: 'Nie', value: false },
          ],
        },
      },
      {
        accessorKey: 'duration',
        header: 'Czas parkowania',
      },
    ],
    [t, lang]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      tickets?.map(
        ({
          id,
          carPlate,
          carPlate2,
          user: { login, firstName, lastName, tenant, accessCardId },
          parkingName,
          invoiceNeeded,
          buyer,
          payment,
          validFrom,
          validTo,
          parkingPass,
          productSeller,
          duration,
        }) => ({
          id,
          paymentId: payment?.paymentId,
          paymentDate:
            payment?.createdAt && onlyDateIso2ExcelFormat(payment.createdAt),
          paymentTime:
            payment?.createdAt && timeIso2ExcelFormat(payment.createdAt),
          paymentDay:
            payment?.createdAt &&
            dateIso2localeString(payment.createdAt, lang, {
              weekday: 'long',
            }),
          validFromDay: dateIso2localeString(validFrom, lang, {
            weekday: 'long',
          }),
          sessionId: payment?.sessionId,
          createdAt: payment?.createdAt,
          createdAtCSV:
            payment?.createdAt && dateIso2ExcelFormat(payment.createdAt),
          validFrom,
          validFromCSV: dateIso2ExcelFormat(validFrom),
          validTo,
          validToCSV: dateIso2ExcelFormat(validTo),
          productName: parkingPass ? parkingPass.name : t('oneTimeTicket'),
          parkingName: parkingName
            ? parkingName
            : parkingPass?.parking.parkingName,
          simplifiedInvoice: payment?.seller
            ? payment.seller.simplifiedInvoice?.enabled
            : productSeller
            ? productSeller.simplifiedInvoice?.enabled
            : parkingPass?.seller
            ? parkingPass?.seller.simplifiedInvoice?.enabled
            : parkingPass?.parking.seller.simplifiedInvoice
            ? parkingPass?.parking.seller.simplifiedInvoice.enabled
            : false,
          fv: invoiceNeeded,
          fvCSV: invoiceNeeded ? t('bool:yes') : t('bool:no'),
          login,
          fullName: `${firstName} ${lastName}`,
          accessCardId,
          nip: buyer?.nip,
          buyerName: buyer?.buyerName,
          zipcode: buyer?.zipcode,
          street: buyer?.street,
          city: buyer?.city,
          country: buyer?.country,
          carPlate: carPlate2 ? `${carPlate}, ${carPlate2}` : carPlate,
          tenantName: tenant?.tenantName,
          status: payment?.status,
          price: payment?.amount ? formatPrice(payment.amount) : '0',
          priceCSV: payment?.amount ? formatToExcelPrice(payment.amount) : '0',
          sandbox: payment?.sandbox ? 'sandbox' : undefined,
          logs: payment?.logs,
          invoiceEmail: payment?.invoiceEmail,
          seller: payment?.seller
            ? payment.seller
            : productSeller
            ? productSeller
            : parkingPass?.seller
            ? parkingPass?.seller
            : parkingPass?.parking.seller,
          duration: duration && formatDuration(duration),
        })
      ) || []
    )
  }, [tickets, t, lang])

  useEffect(() => {
    if (data) {
      setTickets([
        ...data.queryOneTimeParkingTicket,
        ...data.queryParkingTicket,
      ])
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (paymentId) {
      setOpenedDetails(
        tableData?.find(({ id }: { id: string }) => id === paymentId)
      )
    }
  }, [paymentId, tableData])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('parking_payment_history')}
      </TypographyStyled>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        defaultColumnFilter={usr ? [{ id: 'login', value: usr }] : []}
        onRowClick={(rowData: Row) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status' && !!row.original.status
            ? {
                style: {
                  color: getPaymentStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <PaymentDetails
          closeDetails={() => setOpenedDetails(undefined)}
          data={openedDetails}
          refetch={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default PaymentHistory

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
