import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { FormControl, MenuItem, TextField } from '@mui/material'
import { AutoCompleteType, IFormError, InputRules } from '../../types/FormTypes'
import { themeColors } from '../../const/colors'
import { Controller } from 'react-hook-form'

export type OptionType = { value: string; label: string }

const SurveyQuestionSelect: React.FC<{
  label: string
  name: string
  control: any
  errors: IFormError | any
  errorIndex?: number
  rules?: InputRules
  defaultValue?: string
  message?: string
  autoCompleteType?: AutoCompleteType
  InputProps?: any
  options: OptionType[]
  [k: string]: any
}> = forwardRef(
  (
    {
      label,
      name,
      control,
      errors,
      errorIndex,
      rules,
      defaultValue = '',
      message = '',
      autoCompleteType,
      children,
      InputProps,
      options,
      ...rest
    },
    ref?: React.Ref<HTMLInputElement>
  ) => {
    const checkError = () =>
      !!errors.questions && errorIndex && !!errors.questions[errorIndex].type

    return (
      <>
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field: { onChange, value, ref } }) => (
            <FormControl fullWidth>
              <Select
                ref={ref}
                id="select"
                variant="outlined"
                color={'primary'}
                value={value}
                onChange={onChange}
                label={label}
                select
                error={checkError() || undefined}
                SelectProps={{
                  MenuProps: { disablePortal: true },
                }}
              >
                {options.map(({ label, value }, i) => (
                  <MenuItem value={value} key={i}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </>
    )
  }
)

export default SurveyQuestionSelect

const Select = styled(TextField)`
  background-color: ${themeColors.white};
  max-width: 500px;
`
