import React, { FC } from 'react'
import { useAppSelector } from '../../redux/store'
import { ListItemIcon } from '@mui/material'
import { faPoll } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { checkRole } from '../../utils/checkRole'
import { SURVEY_ROLES } from '../../const/permissions'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  MainListItem,
  CustomListItemText,
  LinkStyled,
} from '../Styles/NavListStyle'

interface ISurveyListItemProps {
  isMenuOpen?: boolean
}

const SurveyListItem: FC<ISurveyListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])
  let { pathname } = useLocation()
  const { roles } = useAppSelector((state) => state.user)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : `/admin/${route}` === pathname

  if (!checkRole(roles, SURVEY_ROLES)) {
    return null
  }

  return (
    <MainListItem
      selected={isSelected(ROUTES.SURVEY_HOME)}
      data-e2e="navigation-survey-list"
    >
      <LinkStyled to={ROUTES.SURVEY_HOME}>
        <ListItemIcon>
          <FAIcon icon={faPoll as IconProp} />
        </ListItemIcon>
        <CustomListItemText primary={t('surveys')} />
      </LinkStyled>
    </MainListItem>
  )
}

export default SurveyListItem
